import React from "react";
import { AudienceAge } from "../interfaces/celeb.interfaces";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

interface AudienceAgeChartProps {
  data: AudienceAge[];
}

const AudienceAgeChart = ({ data }: AudienceAgeChartProps) => {
  const maleData = data.find((d) => d.gender === "male")?.byAgeGroup || [];
  const femaleData = data.find((d) => d.gender === "female")?.byAgeGroup || [];

  const chartData = maleData.map((group, index) => ({
    group: group.group,
    male: group.value,
    female: femaleData[index]?.value || 0,
  }));
  return (
    <ResponsiveContainer width="100%" height={210}>
      <BarChart width={730} height={250} data={chartData}>
        <XAxis dataKey="group" tick={false} axisLine={false} tickLine={false} />
        <Tooltip />
        <Bar dataKey="female" fill="#F35F8D" radius={[10, 10, 0, 0]} />
        <Bar dataKey="male" fill="#8BC6FF" radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AudienceAgeChart;
