export function formatNumber(num: number): string {
  if (Math.abs(num) >= 1000000000) {
    return (num / 1000000000).toFixed(1) + "B";
  } else if (Math.abs(num) >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (Math.abs(num) >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num.toString();
}

export function formatNumberWithCommas(num: number | null): string {
  if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "";
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date
    .toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    })
    .replace(",", ".");
};

export default formatDate;

export const countryList = [
  { id: "SOUTH_KOREA", value: "SOUTH_KOREA", label: "South Korea" },
  { id: "USA", value: "USA", label: "USA" },
  { id: "FRANCE", value: "FRANCE", label: "France" },
  { id: "UK", value: "UK", label: "UK" },
  { id: "GERMANY", value: "GERMANY", label: "Germany" },
  { id: "JAPAN", value: "JAPAN", label: "Japan" },
  { id: "CHINA", value: "CHINA", label: "China" },
  { id: "INDONESIA", value: "INDONESIA", label: "Indonesia" },
];

export const mediaList = [
  {
    id: "INSTAGRAM",
    value: "INSTAGRAM",
    label: "Instagram",
    img: "/svg/instagram.svg",
    imgDisabled: "/svg/instagram_disabled.svg",
  },
  {
    id: "FACEBOOK",
    value: "FACEBOOK",
    label: "Facebook",
    img: "/svg/facebook.svg",
    imgDisabled: "/svg/facebook_disabled.svg",
  },
  {
    id: "YOUTUBE",
    value: "YOUTUBE",
    label: "Youtube",
    img: "/svg/youtube.svg",
    imgDisabled: "/svg/youtube_disabled.svg",
  },
  {
    id: "TWITTER",
    value: "TWITTER",
    label: "X",
    img: "/svg/x.svg",
    imgDisabled: "/svg/x_disabled.svg",
  },
  {
    id: "PINTEREST",
    value: "PINTEREST",
    label: "Pinterest",
    img: "/svg/pinterest.svg",
    imgDisabled: "/svg/pinterest_disabled.svg",
  },
  {
    id: "TIKTOK",
    value: "TIKTOK",
    label: "Tik Tok",
    img: "/svg/tiktok.svg",
    imgDisabled: "/svg/tiktok_disabled.svg",
  },
];

export const currencyList = [
  {
    id: "USD",
    value: "USD",
    label: "USD",
    sign: "$",
  },
  {
    id: "KRW",
    value: "KRW",
    label: "KRW",
    sign: "₩",
  },
  {
    id: "EUR",
    value: "EUR",
    label: "EUR",
    sign: "€",
  },
];

interface StatusItem {
  value: string;
  label: string;
  statusLabel: string;
}

export const findMatchingStatusLabel = (
  array: StatusItem[],
  value: string | null
): string => {
  return array.find((item) => item.value === value)?.statusLabel || "";
};
