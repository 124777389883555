import React, { useState } from "react";
import SelectAgency from "./SelectAgency";
import styled from "styled-components";
import SignupForm from "./SignupForm";
import Agreement from "./Agreement";
import FormContainer, { StepDescription } from "../common/FormContainer";

export const Continue = styled.button`
  display: flex;
  width: 100%;
  height: 6rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  background-color: var(--purple-300);
  color: var(--white);
  margin-top: 4rem;
  &:disabled {
    background-color: var(--gray-500);
    color: var(--gray-050);
    cursor: not-allowed;
  }
`;

export interface AgreeCheck {
  agree: boolean;
  marketAgree: boolean;
}

const Signup = () => {
  const [step, setStep] = useState<number>(1);
  const [agencyType, setAgencyType] = useState<string | null>(null);
  const [agreeStatus, setAgreeStatus] = useState<AgreeCheck>({
    agree: false,
    marketAgree: false,
  });

  const nextStepHandler = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const renderContent = () => {
    switch (step - 1) {
      case 1: {
        return (
          <>
            <StepDescription step={step} length={4}>
              Choose whether you're a Celebrity or an Advertising Agency.
            </StepDescription>
            <Agreement
              agreeStatus={agreeStatus}
              setAgreeStatus={setAgreeStatus}
              buttonFn={nextStepHandler}
            />
          </>
        );
      }
      case 2: {
        return (
          <>
            <StepDescription step={step} length={4} />
            <SignupForm agreeStatus={agreeStatus} companyType={agencyType} />
          </>
        );
      }
      default: {
        return (
          <>
            <StepDescription step={step} length={4}>
              Choose whether{"\n"}you're a Celebrity or an Advertising Agency.
            </StepDescription>
            <SelectAgency
              agencyType={agencyType}
              setAgencyType={setAgencyType}
              buttonFn={nextStepHandler}
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <FormContainer logo={true}>{renderContent()}</FormContainer>
    </>
  );
};

export default Signup;
