import styled from "styled-components";

export const HashtagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.4rem 0.8rem;
  li {
    display: inline-flex;
    align-items: center;
    color: var(--gray-600);
    border: 0.1rem solid var(--gray-600);
    border-radius: 2rem;
    background: var(--gray-050);
    padding: 0.4rem 1.2rem 0.2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    line-height: 130%;
  }
`;

export const KeywordList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 0.8rem 0.4rem;
  li {
    display: inline-flex;
    border: 0.1rem solid var(--gray-900);
    border-radius: 2rem;
    padding: 0.2rem 1rem;
    background: var(--gray-050);
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;
