import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Apply from "../button/Apply";
import RangeSlider from "../common/RangeSlider";
import Country from "../select/Country";
import { FilterQuery } from "./CelebList";
import Checkbox from "../common/Checkbox";

const CelebListSidebarContainer = styled.div`
  width: 32rem;
  height: 100vh;
  flex-shrink: 0;
  border-right: 0.1rem solid var(--gray-100);
  background: var(--white);
  .title {
    padding: 4rem 3.2rem 2rem;
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
  }
`;

const FilterMenuContainer = styled.ul`
  li:first-child {
    border-top: 0.1rem solid var(--gray-100);
  }
`;

const FilterMenu = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3.2rem;
  border-bottom: 0.1rem solid var(--gray-100);
  color: var(--gray-900);
  cursor: pointer;
  &:hover,
  &.selected {
    color: var(--purple-500);
    background-color: var(--purple-050);
  }
`;

const FilterDetail = styled.li`
  padding: 2rem 3.2rem;
  border-bottom: 0.1rem solid var(--gray-100);
  color: var(--gray-900);
`;

const CheckboxContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.4rem;
`;

interface CelebListSidebarProps {
  query: FilterQuery;
  setQuery: Function;
  refetch: () => void;
}

const celebType = [
  {
    label: "Musician",
    value: "musician",
  },
  {
    label: "Athlete",
    value: "athlete",
  },
  {
    label: "Model",
    value: "model",
  },
  {
    label: "Singer",
    value: "singer",
  },
  {
    label: "Actor",
    value: "actor",
  },
];

const CelebListSidebar = ({
  query,
  setQuery,
  refetch,
}: CelebListSidebarProps) => {
  const [celebTypeStatus, setCelebTypeStatus] = useState<boolean>(false);
  const [countryStatus, setCountryStatus] = useState<boolean>(false);
  const [reach, setReach] = useState<boolean>(false);
  const [price, setPrice] = useState<boolean>(false);

  return (
    <CelebListSidebarContainer>
      <p className="title">Filters</p>
      <FilterMenuContainer>
        <FilterMenu
          className={reach ? "selected" : ""}
          onClick={() => setReach(!reach)}
        >
          Estimated Reach
          {reach ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {reach ? (
          <FilterDetail>
            <RangeSlider
              title="Estimated Reach"
              width={236}
              max={1000000}
              minValue={query.minEstimatedReach}
              maxValue={query.maxEstimatedReach}
              name="EstimatedReach"
              setQuery={(name, value) => setQuery(name, value)}
            />
          </FilterDetail>
        ) : (
          <></>
        )}
        <FilterMenu
          className={price ? "selected" : ""}
          onClick={() => setPrice(!price)}
        >
          Estimated Price
          {price ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {price ? (
          <FilterDetail>
            <RangeSlider
              title="Estimated Price ($)"
              width={236}
              max={10000}
              minValue={query.minEstimatedPrice}
              maxValue={query.maxEstimatedPrice}
              name="EstimatedPrice"
              setQuery={(name, value) => setQuery(name, value)}
            />
          </FilterDetail>
        ) : (
          <></>
        )}
        <FilterMenu
          className={celebTypeStatus ? "selected" : ""}
          onClick={() => setCelebTypeStatus(!celebTypeStatus)}
        >
          Celeb type
          {celebTypeStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {celebTypeStatus ? (
          <FilterDetail>
            <CheckboxContainer>
              {celebType.map((type, index) => {
                return (
                  <Checkbox
                    key={"celeb_type_checkbox" + type.value}
                    label={type.label}
                    value={type.value}
                    checked={query.type.includes(type.value)}
                    onChange={(value, checked) =>
                      setQuery("type", value, checked)
                    }
                  />
                );
              })}
            </CheckboxContainer>
          </FilterDetail>
        ) : (
          <></>
        )}
        <FilterMenu
          className={countryStatus ? "selected" : ""}
          onClick={() => setCountryStatus(!countryStatus)}
        >
          Country
          {countryStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </FilterMenu>
        {countryStatus ? (
          <>
            <FilterDetail>
              <Country query={query} setQuery={setQuery} />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Instagram Followers"
                width={236}
                max={1000000}
                minValue={query.minInstagramFollowers}
                maxValue={query.maxInstagramFollowers}
                name="InstagramFollowers"
                setQuery={(name, value) => setQuery(name, value)}
              />
            </FilterDetail>
            <FilterDetail>
              <RangeSlider
                title="Search Volume"
                width={236}
                max={1000000}
                minValue={query.minSearchVolume}
                maxValue={query.maxSearchVolume}
                name="SearchVolume"
                setQuery={(name, value) => setQuery(name, value)}
              />
            </FilterDetail>
          </>
        ) : (
          <></>
        )}
        <FilterDetail>
          <Apply refetch={refetch} />
        </FilterDetail>
      </FilterMenuContainer>
    </CelebListSidebarContainer>
  );
};

export default CelebListSidebar;
