import React from "react";
import { Area, AreaChart, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { FollowersChart } from "../interfaces/celeb.interfaces";

interface FollowersGrowthChartProps {
  data: FollowersChart[];
}

const FollowersGrowthChart = ({ data }: FollowersGrowthChartProps) => {
  return (
    <ResponsiveContainer width="100%" height={210}>
      <AreaChart data={data}>
        <XAxis dataKey="date" tick={false} axisLine={false} tickLine={false} />
        {/*<YAxis tick={false} axisLine={false} tickLine={false} />*/}
        <Tooltip />
        <Area
          type="monotone"
          dataKey="count"
          stroke="var(--purple-300)"
          fill="var(--purple-050)"
          strokeWidth={3}
          dot={{ stroke: "var(--purple-300)", strokeWidth: 3, r: 2 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default FollowersGrowthChart;
