import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../api/auth";
import {
  BaseResponse,
  UserProfileResponse,
} from "../components/interfaces/auth.interfaces";
import { useAtom } from "jotai";
import { userDataAtom } from "../atoms/auth";
import { useEffect } from "react";

interface Fail {
  message: string;
  error: string;
  statusCode: number;
}

type ProfileFailResponse = BaseResponse<Fail>;

export const useAuth = () => {
  const [, setUserData] = useAtom(userDataAtom);
  const { data, isPending, refetch } = useQuery<
    UserProfileResponse | ProfileFailResponse
  >({
    queryKey: ["isAuthenticated"],
    queryFn: getProfile,
    retry: false,
  });

  const isSuccess = (
    data: UserProfileResponse | ProfileFailResponse | undefined
  ): data is UserProfileResponse => {
    if (!data) return false;
    return data.ok;
  };

  useEffect(() => {
    if (isSuccess(data)) {
      setUserData(data.data);
    }
  }, [data, setUserData]);

  const isAuthenticated = isSuccess(data);
  const user = isAuthenticated ? data.data : null;

  return {
    data,
    isPending,
    refetch,
    isAuthenticated,
    user,
  };
};
