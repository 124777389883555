import React from "react";
import { CelebData } from "../interfaces/celeb.interfaces";
import Section from "../layout/Section";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

const CelebImageNewsWrapper = styled.div`
  width: 44rem;
  height: 42rem;
  border-radius: 0.4rem;
  overflow: hidden;
  background-color: pink;
`;

const CelebNewsSmall = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .news {
    width: 30rem;
    border-radius: 0.4rem;
    background-color: pink;
    display: flex;
    height: 20rem;
    padding: 2.8rem;
    justify-content: center;
    align-items: center;
  }
`;

const CelebDataCard = styled.div`
  border-radius: 0.4rem;
  background-color: pink;
  display: flex;
  width: 32rem;
  height: 42rem;
  padding: 3.6rem;
  justify-content: center;
  align-items: center;
`;

interface CelebSummarySectionProps {
  celebData: CelebData;
}

const CelebSummarySection = ({ celebData }: CelebSummarySectionProps) => {
  return (
    <Section mt="8rem">
      <Wrapper>
        <CelebImageNewsWrapper></CelebImageNewsWrapper>
        <CelebNewsSmall>
          <div className="news"></div>
          <div className="news"></div>
        </CelebNewsSmall>
        <CelebDataCard></CelebDataCard>
        <CelebDataCard></CelebDataCard>
      </Wrapper>
    </Section>
  );
};

export default CelebSummarySection;
