import React from "react";
import styled from "styled-components";
import { Continue } from "./Signup";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const AgencyButton = styled.div`
  display: flex;
  align-items: center;
  width: 42rem;
  height: 10rem;
  padding: 2rem 0;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  gap: 1.6rem;
  padding: 2rem;
  cursor: pointer;
  &:hover,
  &.selected {
    border: 0.2rem solid var(--purple-050);
    box-shadow: 0rem 0rem 1rem 0rem #f3e7fe;
  }

  .icon {
    .celeb-agency {
      width: 4rem;
      height: 4rem;
      background-image: url("/svg/celeb_agency.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .ad-agency {
      width: 4rem;
      height: 4rem;
      background-image: url("/svg/ad_agency.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

const AgencyDescription = styled.div`
  display: flex;
  flex-direction: column;
  .title {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
  }
  .sub-text {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body3};
    ${({ theme }) => theme.font.regular};
  }
`;

interface SelectAgencyProps {
  agencyType: string | null;
  setAgencyType: (newValue: string) => void;
  buttonFn: () => void;
}

const SelectAgency = ({
  agencyType,
  setAgencyType,
  buttonFn,
}: SelectAgencyProps) => {
  return (
    <Container>
      <AgencyButton
        className={agencyType === "celebrity_agency" ? "selected" : ""}
        onClick={() => setAgencyType("celebrity_agency")}
      >
        <div className="icon">
          <div className="celeb-agency" />
        </div>
        <AgencyDescription>
          <p className="title">Celeb agency</p>
          <p className="sub-text">Celebrity agency, Manager and Aritst</p>
        </AgencyDescription>
      </AgencyButton>
      <AgencyButton
        className={agencyType === "advertiser" ? "selected" : ""}
        onClick={() => setAgencyType("advertiser")}
      >
        <div className="icon">
          <div className="ad-agency" />
        </div>
        <AgencyDescription>
          <p className="title">Ad agency</p>
          <p className="sub-text">
            Advertiser, Brand, Advertising or Marketing Agency
          </p>
        </AgencyDescription>
      </AgencyButton>
      <Continue disabled={!agencyType} onClick={() => buttonFn()}>
        Continue
      </Continue>
    </Container>
  );
};

export default SelectAgency;
