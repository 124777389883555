import React from "react";
import styled from "styled-components";

import { useNavigate, useSearchParams } from "react-router-dom";
import { ContractListData } from "../../interfaces/contract.interface";
import { findMatchingStatusLabel } from "../../../utils/utils";
import { statusList } from "../../../configs/contractStatus";

const ContractListContainer = styled.div`
  margin-top: 6rem;
`;

const Title = styled.p`
  color: var(--gray-900);
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
`;

const TableContainer = styled.ul`
  margin-top: 2.8rem;
  border-top: 0.5rem solid var(--gray-900);
  > :first-child {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    border-bottom: 0.1rem solid var(--gray-900);
    cursor: default;
  }
`;

const TableRow = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: 1.2fr 1fr 2fr 1fr 4fr 1fr;
  padding: 2rem 2rem;
  height: 6rem;
  text-align: center;
  border-bottom: 0.1rem solid var(--gray-300);
  cursor: pointer;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    &.status {
      width: 100%;
      border-radius: 1.2rem;
      padding: 0.2rem 1rem;
      color: var(--gray-050);
      background-color: var(--purple-300);
    }
    &.bold {
      ${({ theme }) => theme.font.bold};
    }
    &.italic {
      ${({ theme }) => theme.font.italic};
    }
  }
`;

interface ContractListProps {
  contractList: ContractListData[];
}

const ContractList = ({ contractList }: ContractListProps) => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");
  const navigate = useNavigate();

  return (
    <ContractListContainer>
      <Title>Contract lists</Title>
      <TableContainer>
        <TableRow>
          <span>Status</span>
          <span>Contract #.</span>
          <span>Celebrity name</span>
          <span>Agency</span>
          <span>Type</span>
          <span>Amount</span>
        </TableRow>
        {contractList.map((contract) => (
          <TableRow
            key={contract.id}
            onClick={() =>
              navigate(
                `../contract?projectId=${projectId}&contractId=${contract.contractId}`,
                { relative: "route" }
              )
            }
          >
            <span className="status">
              {findMatchingStatusLabel(statusList, contract.status)}
            </span>
            <span>{contract.id}</span>
            <span className="bold">{contract.celebrityName}</span>
            <span className="bold">{contract.agency}</span>
            <span>{contract.contractType}</span>
            <span>{contract.amount}</span>
          </TableRow>
        ))}
      </TableContainer>
    </ContractListContainer>
  );
};

export default ContractList;
