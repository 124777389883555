import React from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled, { css } from "styled-components";

const sizeStyle = {
  sm: css`
    font-size: 1.6rem;
  `,
  md: css`
    font-size: 2.4rem;
  `,
  lg: css`
    font-size: 4rem;
  `,
  default: css`
    font-size: 2.4rem;
  `,
};

const CheckButton = styled.div<{
  $status: boolean;
  $size: "sm" | "md" | "lg" | "default";
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ $status }) =>
    $status ? "var(--purple-300)" : "var(--gray-500)"};
  ${({ $size }) => sizeStyle[$size]};
  stroke-width: 1;
`;

interface CheckProps {
  checked: boolean;
  onClickFn?: () => void;
  size?: "sm" | "md" | "lg" | "default";
}

const Check = ({ checked, onClickFn, size = "default" }: CheckProps) => {
  const icon = checked ? (
    <CheckCircleIcon
      fontSize="inherit"
      sx={{
        stroke: "var(--purple-300)",
        strokeWidth: "0.05rem",
      }}
    />
  ) : (
    <CheckCircleOutlineIcon
      fontSize="inherit"
      sx={{
        stroke: "var(--white)",
        strokeWidth: "0.05rem",
      }}
    />
  );

  return (
    <CheckButton onClick={onClickFn} $status={checked} $size={size}>
      {icon}
    </CheckButton>
  );
};

export default Check;
