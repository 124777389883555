import React from "react";
import Celeb from "../components/celeb/Celeb";
import Layout from "../components/layout/Layout";

const CelebPage = () => {
  return (
    <Layout>
      <Celeb />
    </Layout>
  );
};

export default CelebPage;
