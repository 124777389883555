import React from "react";
import styled from "styled-components";
import { ErrorMessage, Label, LabelGroup } from "../input/ContractInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const MediaButtonList = styled.div`
  display: flex;
  width: 100%;
  gap: 1.6rem;
  padding: 0 0.4rem;
`;

const MediaButton = styled.button<{
  $url: string;
  $disabledUrl: string;
  $status: boolean;
}>`
  width: 4rem;
  height: 4rem;
  ${(props) =>
    props.$status
      ? `background-image: url(${props.$url})`
      : `background-image: url(${props.$disabledUrl})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const mediaType = [
  {
    id: "1",
    value: "INSTAGRAM",
    label: "Instagram",
    img: "/svg/instagram.svg",
    disabledImg: "/svg/instagram_disabled.svg",
  },
  {
    id: "2",
    value: "FACEBOOK",
    label: "Facebook",
    img: "/svg/facebook.svg",
    disabledImg: "/svg/facebook_disabled.svg",
  },
  {
    id: "3",
    value: "YOUTUBE",
    label: "Youtube",
    img: "/svg/youtube.svg",
    disabledImg: "/svg/youtube_disabled.svg",
  },
  {
    id: "4",
    value: "TWITTER",
    label: "X",
    img: "/svg/x.svg",
    disabledImg: "/svg/x_disabled.svg",
  },
  {
    id: "5",
    value: "PINTEREST",
    label: "Pinterest",
    img: "/svg/pinterest.svg",
    disabledImg: "/svg/pinterest_disabled.svg",
  },
  {
    id: "6",
    value: "TIKTOK",
    label: "Tik Tok",
    img: "/svg/tiktok.svg",
    disabledImg: "/svg/tiktok_disabled.svg",
  },
];
interface MediaTypeButtonProps {
  mediaList: string[];
  onChange: (value: string[]) => void;
  errorMessage: string;
}

const MediaTypeButton = ({
  mediaList,
  onChange,
  errorMessage,
}: MediaTypeButtonProps) => {
  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>Advertising media</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <MediaButtonList>
        {mediaType.map((data) => {
          const isSelected = mediaList.includes(data.value);
          return (
            <MediaButton
              type="button"
              $url={data.img}
              $disabledUrl={data.disabledImg}
              key={data.id}
              $status={isSelected}
              onClick={(e) => {
                e.preventDefault();
                onChange(
                  isSelected
                    ? mediaList.filter((value) => value !== data.value)
                    : [...mediaList, data.value]
                );
              }}
            />
          );
        })}
        <MediaButton
          type="button"
          $url="/svg/plus_circle.svg"
          $disabledUrl="/svg/pinterest_disabled.svg"
          $status={true}
        />
      </MediaButtonList>
    </Container>
  );
};

export default MediaTypeButton;
