import React from "react";
import { LayoutWithOutNavbar } from "../components/layout/Layout";
import RequestContract from "../components/contract/RequestContract";

const RequestContractPage = () => {
  return (
    <LayoutWithOutNavbar>
      <RequestContract />
    </LayoutWithOutNavbar>
  );
};

export default RequestContractPage;
