import React from "react";
import styled from "styled-components";
import { ContractFormData } from "../interfaces/contract.interface";
import { ProjectData } from "../interfaces/project.interface";
import StyledButton from "../button/StyledButton";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { InputValidationErrorResponse } from "../interfaces/auth.interfaces";
import { createContract } from "../../api/contract";
import ImageScroll from "../common/ImageScroll";

const Title = styled.div`
  width: 50%;
  margin-right: auto;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--gray-900);
`;

const ContractSection = styled.div`
  width: 100%;
  b {
    ${({ theme }) => theme.font.bold};
  }
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Flex = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Agreement = styled.div`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-900);
  white-space: pre-wrap;
  text-align: left;
  margin-right: auto;
`;

interface ReviewProps {
  contractData: ContractFormData;
  celebName: string;
  projectData: ProjectData | null;
  nextStepHandler: () => void;
  prevStepHandler: () => void;
}

//const Review = () => {
const Review = ({
  contractData,
  celebName,
  projectData,
  nextStepHandler,
  prevStepHandler,
}: ReviewProps) => {
  const mutation = useMutation<
    AxiosResponse,
    AxiosError<InputValidationErrorResponse>,
    ContractFormData
  >({
    mutationFn: createContract,
    onSuccess: (data) => {
      nextStepHandler();
    },
    onError: (error) => {},
  });

  const onClick = () => {
    console.log(contractData);
    mutation.mutate(contractData);
  };

  if (projectData)
    return (
      <>
        <ContractSection>
          <Title>Contractors</Title>
          <FlexColumn>
            <Flex>
              <b>Party A</b>
              <span>aa</span>
            </Flex>
            <Flex>
              <b>Party A</b>
              <span>{celebName}</span>
            </Flex>
          </FlexColumn>
        </ContractSection>
        <div
          style={{ width: "100%", borderTop: "0.4rem solid var(--gray-900)" }}
        />
        <Agreement>
          This contract defines the terms and conditions{"\n"}
          between the parties, detailing roles, responsibilities,{"\n"}
          payment terms, and duration. It also covers{"\n"}
          confidentiality, dispute resolution, and termination{"\n"}
          clauses. By signing, both parties agree to the stated{"\n"}
          terms and their obligations.
        </Agreement>
        <ContractSection>
          <Title>Contract Details</Title>
          <FlexColumn>
            <b>Project title</b>
            <span>{projectData.title}</span>
          </FlexColumn>
          <FlexColumn>
            <b>Project description</b>
            <span>{projectData.description}</span>
          </FlexColumn>
          <Flex>
            <b>Amount</b>
            <span>{contractData.amount}</span>
          </Flex>
          <Flex>
            <b>Ad posting date</b>
            <span>
              {contractData.startDate}-{contractData.endDate}
            </span>
          </Flex>
          <Flex>
            <b>Target countries</b>
            <span>
              {contractData.country.length
                ? contractData.country.join(", ")
                : "N/A"}
            </span>
          </Flex>
          <Flex>
            <b>Advertising media</b>
            <span>
              {contractData.advertisingMedia.length
                ? contractData.advertisingMedia.join(", ")
                : "N/A"}
            </span>
          </Flex>
          <FlexColumn>
            <b>Photos to be used</b>
            <ImageScroll
              imgList={contractData.stockImage}
              width={8}
              height={8}
              maxWidth={42}
              gap={0.8}
            ></ImageScroll>
            {/*<ImageListContainer>
              <ImageList $length={contractData.stockImage.length * 3}>
                {contractData.stockImage.map((stock) => {
                  return (
                    <>
                      <img src={stock} width={80} height={80} />
                      <img src={stock} width={80} height={80} />
                      <img src={stock} width={80} height={80} />
                    </>
                  );
                })}
              </ImageList>
            </ImageListContainer>*/}
          </FlexColumn>
          {contractData.additionalCondition && (
            <FlexColumn>
              <b>Additional condition</b>
              <span>{contractData.additionalCondition}</span>
            </FlexColumn>
          )}
          {contractData.comment && (
            <FlexColumn>
              <b>Comments</b>
              <span>{contractData.comment}</span>
            </FlexColumn>
          )}
        </ContractSection>
        <StyledButton
          type="button"
          label="Confirm"
          color="black"
          size="lg"
          onClick={() => onClick()}
        />
      </>
    );
  return <></>;
};

export default Review;
