import React from "react";
import styled from "styled-components";
import StyledButton from "../button/StyledButton";
import { useNavigate } from "react-router-dom";

const Title = styled.div`
  width: 100%;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.bold};
  color: var(--gray-900);
`;

const Description = styled.div`
  width: 100%;
  margin-top: 1.6rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  white-space: pre-wrap;
  color: var(--gray-600);
`;

const Complete = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title>Request Complete!</Title>
      <Description>
        Please note that agreeing to all includes both mandatory {"\n"}
        and optional information.{"\n"}
        You may choose to agree to each item individually.{"\n"}
        Even if you refuse to consent to optional items,{"\n"}
        you can still use the service.
      </Description>
      <StyledButton
        size="lg"
        color="black"
        label="OK"
        margin="4rem auto 0"
        onClick={() => navigate(-1)}
      />
    </>
  );
};

export default Complete;
