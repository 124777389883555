import React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { formatNumber } from "../../utils/utils";
import styled from "styled-components";

const minDistance = 1000;

const SliderContainer = styled.div`
  color: var(--gray-700);
`;

const SliderTitle = styled.div`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  width: 100%;
  margin: 0 auto 1.4rem;
`;

const RangeViewer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--gray-700);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  margin: 0.8rem auto 0;
`;

interface RangeSlideProps {
  title: string;
  width: number;
  min?: number;
  max?: number;
  minValue: number;
  maxValue: number;
  name: string;
  setQuery: (name: string, value: number) => void;
}

const RangeSlider = ({
  title,
  width,
  min = 0,
  max = 100000,
  minValue,
  maxValue,
  name,
  setQuery,
}: RangeSlideProps) => {
  const handleChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setQuery(`min${name}`, Math.min(newValue[0], maxValue - minDistance));
    } else {
      setQuery(`max${name}`, Math.max(newValue[1], minValue + minDistance));
    }
  };

  return (
    <SliderContainer>
      <SliderTitle>{title}</SliderTitle>
      <Box sx={{ width: width, margin: "0 auto" }}>
        <Slider
          sx={{
            color: "var(--purple-300)",
            "& .MuiSlider-thumb": {
              "&:hover, &:active": {
                color: "var(--purple-500)",
              },
            },
            "& .MuiSlider-rail": {
              color: "var(--gray-200)",
            },
            "& .MuiSlider-track": {
              border: "1px solid var(--purple-050)",
              color: "var(--purple-050)",
            },
          }}
          getAriaLabel={(value) => `${value}`}
          min={min}
          max={max}
          value={[minValue, maxValue]}
          onChange={handleChange}
          disableSwap
        />
      </Box>
      <RangeViewer>
        <div>from {formatNumber(minValue)}~</div>
        <div>{formatNumber(maxValue)}</div>
      </RangeViewer>
    </SliderContainer>
  );
};

export default RangeSlider;
