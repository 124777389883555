import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  > .title {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    width: 18rem;
  }
  > .value {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  > .title {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    width: 18rem;
  }
  > .value {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;
