import React from "react";
import Check from "../button/Check";
import styled from "styled-components";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import StyledButton from "../button/StyledButton";
import useStockCartList from "../../hooks/useStockCartList";

const TypeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Type = styled.div`
  width: 42rem;
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 2.6rem 4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  background: var(--white);
  cursor: pointer;
  .text {
    margin-left: 2rem;
  }

  &:hover,
  &.selected {
    border: 0.2rem solid var(--purple-050);
    box-shadow: 0px 0px 10px 0px #f3e7fe;
  }
`;

const CartWrapper = styled.div<{ $length: number | undefined }>`
  font-size: 3.4rem;
  margin-left: auto;
  position: relative;
  &::after {
    display: block;
    position: absolute;
    top: -0.4rem;
    right: -0.4rem;
    content: "${(props) => (props.$length ? props.$length : "")}";
    color: var(--white);
    width: 1.6rem;
    height: 1.6rem;
    text-align: center;
    align-content: center;
    font-size: 1rem;
    background-color: var(--crimson-600);
    border-radius: 50%;
  }
`;

interface SelectTypeProps {
  celebId: string | null;
  seedingType: "STOCK" | "SEEDING" | "STOCK_SEEDING" | "";
  setSeedingType: (
    seedingType: "STOCK" | "SEEDING" | "STOCK_SEEDING" | ""
  ) => void;
  nextStepHandler: () => void;
}

const SelectType = ({
  celebId,
  seedingType,
  setSeedingType,
  nextStepHandler,
}: SelectTypeProps) => {
  const { stockList, isEmpty } = useStockCartList(celebId);

  const handleProjectSelect = (id: "STOCK" | "SEEDING" | "STOCK_SEEDING") => {
    setSeedingType(id === seedingType ? "" : id);
  };

  return (
    <>
      <TypeList>
        {!isEmpty() && (
          <Type
            onClick={() => handleProjectSelect("STOCK")}
            className={seedingType === "STOCK" ? "selected" : ""}
          >
            <Check
              checked={seedingType === "STOCK"}
              onClickFn={() => handleProjectSelect("STOCK")}
              size="lg"
            />
            <p className="text">Use selected photos</p>
            <CartWrapper $length={stockList?.length}>
              <ShoppingCartOutlinedIcon
                fontSize="inherit"
                sx={{
                  stroke: "var(--white)",
                  strokeWidth: "0.1rem",
                }}
              />
            </CartWrapper>
          </Type>
        )}

        <Type
          onClick={() => handleProjectSelect("SEEDING")}
          className={seedingType === "SEEDING" ? "selected" : ""}
        >
          <Check
            checked={seedingType === "SEEDING"}
            onClickFn={() => handleProjectSelect("SEEDING")}
            size="lg"
          />
          <p className="text">Social media seeding</p>
        </Type>
      </TypeList>
      <StyledButton
        onClick={() => nextStepHandler()}
        label="Next"
        size="lg"
        color="black"
        style={{ margin: "4rem auto 0" }}
        disabled={!seedingType}
      />
    </>
  );
};

export default SelectType;
