import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextInput, PasswordInput } from "../input/Input";
import { postSignup } from "../../api/Signup";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse, AxiosError } from "axios";
import styled from "styled-components";
import { EmailInput } from "../input/EmailInput";
import { useNavigate } from "react-router-dom";
import StyledButton from "../button/StyledButton";

const schema = z
  .object({
    email: z.string().email({ message: "Please enter a valid email address." }),
    password: z
      .string()
      .min(8, { message: "Password must meet the required format." }),
    passwordConfirm: z
      .string()
      .min(8, { message: "Password must meet the required format." }),
    phone: z
      .string()
      .min(10, { message: "Please enter a valid phone number." })
      .regex(/^\d+$/, { message: "Please enter a valid phone number." }),
    companyName: z
      .string()
      .min(1, { message: "Please enter a valid company name." }),
    position: z.string().min(1, { message: "Please enter a valid position." }),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords do not match.",
    path: ["passwordConfirm"],
  });

export interface SignupFormData {
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
  companyName: string;
  position: string;
}

interface SignupErrorResponse {
  ok: boolean;
  statusCode: number;
  data: Array<{
    field: string;
    messages: string[];
  }>;
}

const isFieldInSignupFormData = (
  field: string
): field is keyof SignupFormData => {
  return [
    "email",
    "password",
    "passwordConfirm",
    "phone",
    "companyName",
    "position",
  ].includes(field);
};

const SignupFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

interface SignupFormProps {
  companyType: string | null;
  agreeStatus: { agree: boolean; marketAgree: boolean };
}

const SignupForm = ({ companyType, agreeStatus }: SignupFormProps) => {
  const methods = useForm<SignupFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const mutation = useMutation<
    AxiosResponse,
    AxiosError<SignupErrorResponse>,
    SignupFormData
  >({
    mutationFn: postSignup,
    onSuccess: (data) => {
      if (data.data.user.companyType === "advertiser") {
        navigate("/celebs");
      } else if (data.data.user.companyType === "celebrity_agency") {
        navigate("/dashboard");
      }
    },
    onError: (error) => {
      const response = error.response?.data;

      if (response && response.data) {
        response.data.forEach((err) => {
          if (isFieldInSignupFormData(err.field)) {
            setError(err.field, {
              type: "server",
              message: err.messages[0],
            });
          }
        });
      }
    },
  });

  const onSubmit = (data: SignupFormData) => {
    const formData = {
      ...data,
      ...agreeStatus,
      companyType: companyType,
    };
    mutation.mutate(formData);
  };

  return (
    <FormProvider {...methods}>
      <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
        <EmailInput
          label="Email"
          name="email"
          placeholder="Email"
          register={register}
          errorMessage={errors.email?.message}
          setError={setError}
          required={true}
        />
        <PasswordInput
          label="Password"
          name="password"
          placeholder="at least 8 characters long."
          register={register}
          errorMessage={errors.password?.message}
          setError={setError}
          required={true}
        />
        <PasswordInput
          label="Confirm Password"
          name="passwordConfirm"
          placeholder="Confirm password"
          register={register}
          errorMessage={errors.passwordConfirm?.message}
          setError={setError}
          required={true}
        />
        <TextInput
          label="Phone number"
          name="phone"
          type="text"
          placeholder="Phone number"
          register={register}
          errorMessage={errors.phone?.message}
          setError={setError}
          required={true}
        />
        <TextInput
          label="Company name"
          name="companyName"
          type="text"
          placeholder="Company name"
          register={register}
          errorMessage={errors.companyName?.message}
          setError={setError}
          required={true}
        />
        <TextInput
          label="Position"
          name="position"
          type="text"
          placeholder="Position"
          register={register}
          errorMessage={errors.position?.message}
          setError={setError}
          required={true}
        />
        <StyledButton
          type="submit"
          disabled={mutation.isPending}
          label={mutation.isPending ? "Submitting..." : "Continue"}
          color="black"
          size="lg"
        />
      </SignupFormContainer>
    </FormProvider>
  );
};

export default SignupForm;
