import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "./Logo";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useAtom } from "jotai";
import { clearSession, userDataAtom } from "../../atoms/auth";
import { UserInformation } from "../interfaces/auth.interfaces";

const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-bottom: 0.1rem solid var(--gray-300);
  padding: 1.2rem 8rem;
  color: var(--gray-900);
`;

const NavLinkList = styled.div`
  display: flex;
  gap: 2.4rem;
`;

const NavLink = styled(Link)<{ $currentPage: boolean }>`
  color: var(--gray-900);
  text-align: center;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};

  ${({ $currentPage, theme }) => $currentPage && theme.font.bold};
`;

const UserStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const SignIn = styled.button`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: var(--purple-300);
  color: var(--white);
`;

const AccountIconWrapper = styled.div<{ $alert: boolean }>`
  position: relative;
  font-size: 2.4rem;
  &::after {
    display: ${({ $alert }) => ($alert ? "block" : "none")};
    position: absolute;
    top: 0;
    right: 0;
    content: "";
    width: 0.8rem;
    height: 0.8rem;
    background-color: var(--crimson-600);
    border-radius: 50%;
  }
`;

const UserStatus = ({ userData }: { userData: UserInformation | null }) => {
  const navigate = useNavigate();
  const logout = () => {
    clearSession();
    navigate("/");
  };
  if (userData && userData.id)
    return (
      <UserStatusContainer>
        {userData.companyName}
        <AccountIconWrapper $alert={false} onClick={logout}>
          <AccountCircleOutlinedIcon fontSize="inherit" />
        </AccountIconWrapper>
      </UserStatusContainer>
    );
  else {
    return (
      <UserStatusContainer>
        <Link to="/contact-us">Contact us</Link>
        <Link to="/login">
          <SignIn>Sign in</SignIn>
        </Link>
      </UserStatusContainer>
    );
  }
};

const Navbar = () => {
  const [userData, setUserData] = useAtom(userDataAtom);
  const location = useLocation();

  return (
    <NavBarContainer>
      <Logo />
      <NavLinkList>
        <NavLink
          to="/dashboard"
          $currentPage={location.pathname === "/dashboard"}
        >
          Dashboard
        </NavLink>
        <NavLink to="/celebs" $currentPage={location.pathname === "/celebs"}>
          Celebs
        </NavLink>
      </NavLinkList>
      <UserStatus userData={userData} />
    </NavBarContainer>
  );
};

export default Navbar;
