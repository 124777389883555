import React, { useEffect, useState } from "react";
import { ContentBox } from "./ContentBox";
import { AdvertisingProductImage } from "../interfaces/contract.interface";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import AdvertiseCommentList from "./AdvertiseCommentList";
import { useContentList } from "../../hooks/useContract";
import {
  useAdvertiseProductConfirm,
  useAdvertiseProductDelete,
  useAdvertiseProductModify,
} from "../../hooks/useAdvertiseProduct";
import StyledButton from "../button/StyledButton";
import ContentSlot from "./ContentSlot";
import { useContentUpload } from "../../hooks/useContentUpload";

const MainView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  margin: 0 auto;
`;

const CommentContainer = styled.div`
  margin-top: 2rem;
  gap: 2rem;
  border-top: 0.4rem solid var(--black);
  padding-top: 6rem;
`;

const SubView = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  /*margin-right: 1.2rem;*/
`;

const SlotList = styled.div`
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  height: 42rem;
  overflow-y: scroll;
`;

const ContentConfirmButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 2rem;
  top: 1rem;
  right: 1rem;
  display: flex;
`;

const AddNewMediaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 44rem;
  cursor: pointer;
  &.add {
    color: var(--gray-600);
    text-align: center;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    ${({ theme }) => theme.font.underline};
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

const ContentConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  gap: 0.4rem;
  &.delete {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/cancel_circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.modify {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/modify.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

interface ContentReviewProps {
  contractId: string;
  refetch: () => void;
}

const ContentReview = ({ contractId, refetch }: ContentReviewProps) => {
  const { data, isPending, refetch: refetchList } = useContentList(contractId);
  const [selectedImageId, setSelectedImageId] = useState<string>();
  const { modify } = useAdvertiseProductModify({
    onSuccess: () => {
      refetchList();
    },
  });
  const { deleteContent } = useAdvertiseProductDelete({
    onSuccess: () => {
      refetchList();
    },
  });
  const { uploadFile } = useContentUpload({
    onSuccess: (response) => {
      //modify(selectedImage?.advertisingProductId, response);
      refetchList();
    },
  });

  const selectedImage = data?.data.advertisingImgs.find(
    (img) => img.advertisingProductId === selectedImageId
  );

  useEffect(() => {
    if (data?.data.advertisingImgs.length) {
      if (
        !selectedImageId ||
        !data.data.advertisingImgs.find(
          (img) => img.advertisingProductId === selectedImageId
        )
      ) {
        setSelectedImageId(data.data.advertisingImgs[0].advertisingProductId);
      }
    }
  }, [data, selectedImageId]);

  if (isPending) return <>Loading</>;
  if (!data || !selectedImage) return <></>;

  const handleImageSelect = (image: AdvertisingProductImage) => {
    setSelectedImageId(image.advertisingProductId);
  };

  return (
    <ContentBox title="Content Using Stock Images Confirm">
      <MainView>
        <ImageContainer width={42} height={42}>
          <img src={selectedImage.imgUrl} alt="test" />
        </ImageContainer>
        <ContentConfirmButtonContainer>
          <ContentConfirmButton
            className="delete"
            onClick={() => deleteContent(selectedImage.advertisingProductId)}
          >
            Delete
          </ContentConfirmButton>
          <ContentConfirmButton
            className="modify"
            onClick={() => modify(selectedImage.advertisingProductId)}
          >
            Modify
          </ContentConfirmButton>
        </ContentConfirmButtonContainer>
        <AddNewMediaButton className="add" type="button">
          add new media
        </AddNewMediaButton>
        <CommentContainer>
          <AdvertiseCommentList
            advertisingProductId={selectedImage.advertisingProductId}
            commentList={selectedImage.comments}
            contractId={selectedImage.contractId}
            refetch={refetchList}
          />
        </CommentContainer>
        <SubView>
          <SlotList>
            {data.data.advertisingImgs.map((image) => (
              <ContentSlot
                key={image.advertisingProductId}
                onClickFn={handleImageSelect}
                productImage={image}
              />
            ))}
          </SlotList>
        </SubView>
        <StyledButton size="lg" color="black" label="Re-upload Complete" />
      </MainView>
    </ContentBox>
  );
};

export default ContentReview;
