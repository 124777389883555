import React from "react";
import { ContentBox } from "./ContentBox";
import ImageScroll from "../common/ImageScroll";
import { ContractData } from "../interfaces/contract.interface";

interface FinalAdContentProps {
  contractData: ContractData;
}

const FinalAdContent = ({ contractData }: FinalAdContentProps) => {
  return (
    <ContentBox title="Final ad contents">
      <ImageScroll
        imgList={contractData.stockImages}
        maxWidth={104.4}
        width={16}
        height={16}
        gap={1.6}
        unit="rem"
      />
    </ContentBox>
  );
};

export default FinalAdContent;
