import React from "react";
import CelebSummarySection from "./CelebSummarySection";
import HighlightSection from "./HighlightSection";
import styled from "styled-components";
import ReportSection from "./ReportSection";
import CelebNameSection from "./CelebNameSection";
import CelebInformationSection from "./CelebInformationSection";
import { useLocation } from "react-router-dom";
import { getCeleb } from "../../api/celebList";
import { useQuery } from "@tanstack/react-query";

export const SectionTitle = styled.h3`
  ${({ theme }) => theme.font.h3};
  ${({ theme }) => theme.font.bold};
`;

const Celeb = () => {
  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const { data, isPending } = useQuery({
    queryKey: [id, "getCelebData"],
    queryFn: () => getCeleb(id),
  });

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <>
      <CelebNameSection celebInfo={data.data.celebInfo} />
      <CelebInformationSection celebData={data.data} />
      <CelebSummarySection celebData={data.data} />
      <HighlightSection celebData={data.data} />
      <ReportSection celebData={data.data} />
    </>
  );
};

export default Celeb;
