import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import {
  deleteAdvertiseProduct,
  putAdvertiseProductConfirm,
  putAdvertiseProductModify,
  putAdvertiseProductReject,
} from "../api/advertiseProduct";
import { AdvertiseProductStatusSuccessResponse } from "../components/interfaces/advertise.intreface";

interface UseAdvertiseProductConfirmOptions {
  onSuccess?: (response: AdvertiseProductStatusSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useAdvertiseProductConfirm = (
  options?: UseAdvertiseProductConfirmOptions
) => {
  const mutation = useMutation<
    AdvertiseProductStatusSuccessResponse,
    AxiosError,
    string
  >({
    mutationFn: (productId) => putAdvertiseProductConfirm(productId),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const confirm = (productId: string | undefined) => {
    if (!productId) return;
    mutation.mutate(productId);
  };

  return {
    confirm,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

interface useAdvertiseProductRejectOptions {
  onSuccess?: (response: AdvertiseProductStatusSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useAdvertiseProductReject = (
  options?: useAdvertiseProductRejectOptions
) => {
  const mutation = useMutation<
    AdvertiseProductStatusSuccessResponse,
    AxiosError,
    string
  >({
    mutationFn: (productId) => putAdvertiseProductReject(productId),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const reject = (productId: string | undefined) => {
    if (!productId) return;
    mutation.mutate(productId);
  };

  return {
    reject,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

interface useAdvertiseProductModifyOptions {
  onSuccess?: (response: AdvertiseProductStatusSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useAdvertiseProductModify = (
  options?: useAdvertiseProductModifyOptions
) => {
  const mutation = useMutation<
    AdvertiseProductStatusSuccessResponse,
    AxiosError,
    string
  >({
    mutationFn: (productId) => putAdvertiseProductModify(productId),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const modify = (productId: string | undefined) => {
    if (!productId) return;
    mutation.mutate(productId);
  };

  return {
    modify,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

interface useAdvertiseProductDeleteOptions {
  onSuccess?: (response: AdvertiseProductStatusSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useAdvertiseProductDelete = (
  options?: useAdvertiseProductDeleteOptions
) => {
  const mutation = useMutation<
    AdvertiseProductStatusSuccessResponse,
    AxiosError,
    string
  >({
    mutationFn: (productId) => deleteAdvertiseProduct(productId),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const deleteContent = (productId: string | undefined) => {
    if (!productId) return;
    mutation.mutate(productId);
  };

  return {
    deleteContent,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
