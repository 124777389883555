import React from "react";
import { useForm, Controller } from "react-hook-form";
import ContractInput from "../input/ContractInput";
import styled from "styled-components";
import StyledButton from "../button/StyledButton";
import Currency from "../select/Currency";
import ContractPeriod from "../select/ContractPeriod";
import TargetCountryButton from "./TargetCountryButton";
import MediaTypeButton from "./MediaTypeButton";
import AdditionalCondition from "./AdditionalCondition";
import { ContractFormData } from "../interfaces/contract.interface";

const SignupFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

interface ContractFormProps {
  projectId: string | undefined;
  stockList: string[] | undefined;
  celebAgencyId: string;
  celebId: string;
  seedingType: "STOCK" | "SEEDING" | "STOCK_SEEDING";
  setFinalData: (data: ContractFormData) => void;
  nextStepHandler: () => void;
  prevStepHandler: () => void;
}

const ContractForm = ({
  projectId,
  celebAgencyId,
  celebId,
  stockList,
  seedingType,
  setFinalData,
  nextStepHandler,
  prevStepHandler,
}: ContractFormProps) => {
  const today = new Date();
  const startDate = today.toISOString().split("T")[0];

  const afterDate = new Date();
  afterDate.setDate(today.getDate() + 7);
  const endDate = afterDate.toISOString().split("T")[0];
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ContractFormData>({
    //mode: "onBlur",
    defaultValues: {
      projectId: projectId,
      celebAgencyId: celebAgencyId,
      celebId: celebId,
      currencyType: "USD",
      contractType: seedingType,
      amount: 0,
      startDate: startDate,
      endDate: "",
      advertisingMedia: [],
      country: [],
      stockImage: stockList,
      additionalCondition: "",
      comment: "",
    },
  });

  const onSubmit = (data: ContractFormData) => {
    console.log(data);
    setFinalData(data);
    nextStepHandler();
  };

  return (
    <SignupFormContainer onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="currencyType"
        render={({ field }) => (
          <Currency
            control={control}
            currencyType={field.value}
            onChange={field.onChange}
            errorMessage=""
          />
        )}
      />
      <ContractPeriod
        register={register}
        errorMessage={
          errors.startDate || errors.endDate ? "Both dates are required." : ""
        }
      />
      <Controller
        control={control}
        name="country"
        rules={{ required: "Select at least one country." }}
        render={({ field }) => (
          <TargetCountryButton
            country={field.value}
            onChange={field.onChange}
            errorMessage={errors.country?.message || ""}
          />
        )}
      />
      <Controller
        control={control}
        name="advertisingMedia"
        rules={{ required: "Select at least one advertising media." }}
        render={({ field }) => (
          <MediaTypeButton
            mediaList={field.value}
            onChange={field.onChange}
            errorMessage={errors.advertisingMedia?.message || ""}
          />
        )}
      />
      <AdditionalCondition register={register} />
      <div style={{ borderTop: "0.1rem solid var(--gray-300" }} />
      <ContractInput label="Comments" name="comment" register={register} />
      <ButtonContainer>
        <StyledButton
          type="button"
          label="Previous"
          color="white"
          size="sm"
          onClick={() => prevStepHandler()}
        />
        <StyledButton type="submit" label="Complete" color="black" size="sm" />
      </ButtonContainer>
    </SignupFormContainer>
  );
};

export default ContractForm;
