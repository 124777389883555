import React from "react";
import styled from "styled-components";
import { ProjectData } from "../interfaces/project.interface";
import ContractForm from "./ContractForm";
import { CelebStockData } from "../interfaces/celeb.interfaces";
import { ContractFormData } from "../interfaces/contract.interface";

const ProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background-color: var(--gray-100);
  padding: 2rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  .bold {
    ${({ theme }) => theme.font.bold};
  }
  .margin-top {
    margin-top: 2rem;
  }
`;

interface DetailProps {
  selectedProject: ProjectData | null;
  celebName: string;
  celebId: string;
  celebAgencyId: string;
  stockList: CelebStockData[] | [];
  seedingType: "STOCK" | "SEEDING" | "STOCK_SEEDING";
  setFinalData: (data: ContractFormData) => void;
  nextStepHandler: () => void;
  prevStepHandler: () => void;
}

const Detail = ({
  selectedProject,
  celebName,
  celebId,
  celebAgencyId,
  stockList,
  seedingType,
  setFinalData,
  nextStepHandler,
  prevStepHandler,
}: DetailProps) => {
  const imgUrls = stockList.map((data) => data.imgUrl);
  return (
    <>
      <ProjectInformation>
        <div className="bold">{selectedProject?.title}</div>
        <div>{selectedProject?.description}</div>
        <div className="bold margin-top">{celebName}</div>
        <div>Selected {stockList?.length} photos, Social Media Seeding</div>
      </ProjectInformation>
      <ContractForm
        projectId={selectedProject?.id}
        celebAgencyId={celebAgencyId}
        celebId={celebId}
        stockList={imgUrls}
        seedingType={seedingType}
        setFinalData={setFinalData}
        nextStepHandler={nextStepHandler}
        prevStepHandler={prevStepHandler}
      />
    </>
  );
};

export default Detail;
