import React from "react";
import styled, { css } from "styled-components";
import { sizeStyle } from "../common/SizeStyle";

const colorStyle = {
  disabled: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-500);
    color: var(--gray-050);
  `,
  black: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-900);
    color: var(--gray-050);
  `,
  white: css`
    border: 0.1rem solid var(--gray-900);
    border-radius: 0.4rem;
    background-color: var(--gray-050);
    color: var(--gray-900);
  `,
  default: css`
    border: 0.1rem solid transparent;
    border-radius: 0.4rem;
    background-color: var(--gray-900);
    color: var(--gray-050);
  `,
};

interface ButtonProps {
  $size: "xs" | "sm" | "md" | "lg" | "default";
  $color: "black" | "white" | "default";
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
}

const Button = styled.button<ButtonProps>`
  text-align: center;
  ${({ $size }) => sizeStyle[$size]};
  ${({ $color }) => colorStyle[$color]};
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  ${({ $marginTop }) => ($marginTop ? `margin-left: ${$marginTop}` : "")};
  ${({ $marginRight }) =>
    $marginRight ? `margin-right: ${$marginRight}` : ""};
  ${({ $marginBottom }) =>
    $marginBottom ? `margin-bottom: ${$marginBottom}` : ""};
  ${({ $marginLeft }) => ($marginLeft ? `margin-left: ${$marginLeft}` : "")};
  &:disabled {
    ${colorStyle.disabled}
    cursor: not-allowed;
  }
`;

interface StyledButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  size?: "xs" | "sm" | "md" | "lg" | "default";
  color?: "black" | "white" | "default";
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
}

const StyledButton = ({
  label,
  size = "default",
  color = "default",
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  ...options
}: StyledButtonProps) => {
  return (
    <Button
      $size={size}
      $color={color}
      $margin={margin}
      $marginTop={marginTop}
      $marginRight={marginRight}
      $marginBottom={marginBottom}
      $marginLeft={marginLeft}
      {...options}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
