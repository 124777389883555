import { useQuery } from "@tanstack/react-query";
import { CelebInContractResponse } from "../components/interfaces/celeb.interfaces";
import { getCelebInContract } from "../api/celebList";

export const useCelebInContract = () => {
  const { data, isPending, refetch } = useQuery<CelebInContractResponse>({
    queryKey: ["getCelebInContract"],
    queryFn: () => getCelebInContract(),
  });

  return {
    data,
    isPending,
    refetch,
  };
};
