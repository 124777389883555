import React, { useState } from "react";
import styled from "styled-components";
import { UseFormRegister, UseFormSetError } from "react-hook-form";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { SignupFormData } from "../auth/SignupForm";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const LabelGroup = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
`;

const Label = styled.label<{ $required?: boolean }>`
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};

  &::after {
    content: ${({ $required }) => ($required ? "'*'" : "''")};
    color: var(--crimson-600);
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ErrorMessage = styled.p`
  color: var(--crimson-600);
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Input = styled.input<{ $errorMessage?: string }>`
  border-radius: 0.4rem;
  color: var(--gray-600);
  width: 42rem;
  height: 6rem;
  padding: 2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  border: ${({ $errorMessage }) =>
    $errorMessage
      ? "0.1rem solid var(--crimson-600)"
      : "0.1rem solid var(--gray-300)"};
  &:focus {
    border: 0.1rem solid var(--purple-050);
    box-shadow: 0 0 1rem 0 #f3e7fe;
  }
`;

const IconWrapper = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  color: var(--gray-600);
`;

interface SignupInputProps {
  label: string;
  name: string;
  type: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  errorMessage?: string;
  setError: UseFormSetError<SignupFormData>;
  required?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export const TextInput = ({
  label,
  name,
  type,
  placeholder,
  register,
  errorMessage,
  setError,
  required,
  inputProps,
}: SignupInputProps) => {
  return (
    <InputContainer>
      <LabelGroup>
        <Label $required={required}>{label}</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <div style={{ position: "relative" }}>
        <Input
          {...register(name)}
          type={type}
          placeholder={placeholder}
          {...inputProps}
          $errorMessage={errorMessage}
        />
      </div>
    </InputContainer>
  );
};

interface PasswordInputProps {
  label: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  errorMessage?: string;
  setError: UseFormSetError<SignupFormData>;
  required?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

export const PasswordInput = ({
  label,
  name,
  placeholder,
  register,
  errorMessage,
  setError,
  required = false,
  inputProps,
}: PasswordInputProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [inputType, setInputType] = useState<string>("password");

  const onClickVisible = () => {
    if (visible === true) {
      setInputType("password");
    } else {
      setInputType("text");
    }
    setVisible(!visible);
  };

  const visibleIcon = (
    <IconWrapper type="button" onClick={onClickVisible} tabIndex={-1}>
      {visible ? (
        <VisibilityOffOutlinedIcon fontSize="large" />
      ) : (
        <VisibilityOutlinedIcon fontSize="large" />
      )}
    </IconWrapper>
  );

  return (
    <InputContainer>
      <LabelGroup>
        <Label $required={required}>{label}</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <div style={{ position: "relative" }}>
        <Input
          {...register(name)}
          type={inputType}
          placeholder={placeholder}
          {...inputProps}
          $errorMessage={errorMessage}
        />
        {visibleIcon}
      </div>
    </InputContainer>
  );
};
