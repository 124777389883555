import React from "react";
import styled from "styled-components";

interface TypeCheckboxProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string, checked: boolean) => void;
}

const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
  }

  .custom-checkbox {
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid var(--purple-500);
    border-radius: 0.1rem;
    position: relative;
  }

  .custom-checkbox::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  input[type="checkbox"]:checked + .custom-checkbox::after {
    content: " ";
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--purple-500);
    background-image: url("/svg/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .text-label {
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
  }
`;

const Checkbox = ({ label, value, checked, onChange }: TypeCheckboxProps) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value, e.target.checked);
  };

  return (
    <CheckboxContainer>
      <label htmlFor={value}>
        <input
          id={value}
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
        />
        <span className="custom-checkbox"></span>
        <span className="text-label">{label}</span>
      </label>
    </CheckboxContainer>
  );
};

export default Checkbox;
