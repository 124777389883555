import React from "react";
import Layout from "../components/layout/Layout";
import CelebList from "../components/celeb/CelebList";

const CelebListPage = () => {
  return (
    <Layout>
      <CelebList />
    </Layout>
  );
};

export default CelebListPage;
