import React, { useState } from "react";
import CelebListSidebar from "./CelebListSidebar";
import CelebCardList from "./CelebCardList";
import { getCelebList } from "../../api/celebList";
import Filter from "./Filter";
import { useQuery } from "@tanstack/react-query";
import styled from "styled-components";

export interface FilterQuery {
  page: number;
  take: number;
  minEstimatedReach: number;
  maxEstimatedReach: number;
  minEstimatedPrice: number;
  maxEstimatedPrice: number;
  country: string;
  minInstagramFollowers: number;
  maxInstagramFollowers: number;
  minSearchVolume: number;
  maxSearchVolume: number;
  isLike: boolean | null;
  //instagramPopularity: number;
  gender: string[];
  type: string[];
  order__by: string;
}

const defaultQuery: FilterQuery = {
  page: 0,
  take: 0,
  minEstimatedReach: 0,
  maxEstimatedReach: 1000000,
  minEstimatedPrice: 0,
  maxEstimatedPrice: 10000,
  country: "",
  minInstagramFollowers: 0,
  maxInstagramFollowers: 1000000,
  minSearchVolume: 0,
  maxSearchVolume: 1000000,
  isLike: null,
  //instagramPopularity: 0,
  gender: [],
  type: [],
  order__by: "",
};

const Wrapper = styled.div`
  display: flex;
  gap: 4rem;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: "2rem";
`;

const CelebList = () => {
  const [query, setQuery] = useState<FilterQuery>(defaultQuery);
  const { isPending, isError, data, error, refetch } = useQuery({
    queryKey: ["CelebList"],
    queryFn: () => getCelebList(query),
  });

  const queryHandler = (
    name: keyof FilterQuery,
    value: string | number,
    checked?: boolean
  ) => {
    setQuery((prevQuery) => {
      const targetValue = prevQuery[name];
      if (
        checked !== undefined &&
        typeof value === "string" &&
        Array.isArray(targetValue)
      ) {
        if (targetValue.every((item) => typeof item === "string")) {
          const updatedArray = checked
            ? [...targetValue, value]
            : targetValue.filter((item) => item !== value);

          return {
            ...prevQuery,
            [name]: updatedArray,
          };
        }
      }

      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <Wrapper>
      <CelebListSidebar
        query={query}
        setQuery={queryHandler}
        refetch={refetch}
      />
      <MainSection>
        <Filter query={query} setQuery={queryHandler} refetch={refetch} />
        <CelebCardList celebList={data.data} />
      </MainSection>
    </Wrapper>
  );
};

export default CelebList;
