import React from "react";
import styled, { css } from "styled-components";
import { ErrorMessage, Label, LabelGroup } from "../input/ContractInput";

const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const CountryButtonList = styled.div`
  display: flex;
  width: 100%;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

const colorStyle = {
  selected: css`
    background-color: var(--purple-300);
    color: var(--gray-050);
    border: 0.1rem solid var(--purple-300);
  `,
  default: css`
    background-color: var(--white);
    color: var(--gray-400);
    border: 0.1rem solid var(--gray-400);
  `,
};

const countryType = [
  { id: "1", value: "SOUTH_KOREA", label: "South Korea" },
  { id: "2", value: "USA", label: "USA" },
  { id: "3", value: "FRANCE", label: "France" },
  { id: "4", value: "UK", label: "UK" },
  { id: "5", value: "GERMANY", label: "Germany" },
  { id: "6", value: "JAPAN", label: "Japan" },
  { id: "7", value: "CHINA", label: "China" },
  { id: "8", value: "INDONESIA", label: "Indonesia" },
];

const CountryButton = styled.button<{ $status: boolean }>`
  padding: 0.2rem 1rem;
  border-radius: 1.2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  ${({ $status }) => ($status ? colorStyle.selected : colorStyle.default)}
`;

interface TargetCountryButtonProps {
  country: string[];
  onChange: (value: string[]) => void;
  errorMessage: string;
}

const TargetCountryButton = ({
  country,
  onChange,
  errorMessage,
}: TargetCountryButtonProps) => {
  return (
    <CountryContainer>
      <LabelGroup>
        <Label $required={true}>Target countries</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <CountryButtonList>
        {countryType.map((data) => {
          const isSelected = country.includes(data.value);

          return (
            <CountryButton
              key={data.id}
              $status={isSelected}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onChange(
                  isSelected
                    ? country.filter((value) => value !== data.value)
                    : [...country, data.value]
                );
              }}
            >
              {data.label}
            </CountryButton>
          );
        })}
      </CountryButtonList>
    </CountryContainer>
  );
};

export default TargetCountryButton;
