import React, { useState } from "react";
import styled from "styled-components";
import SelectProject from "./SelectProject";
import Review from "./Review";
import Complete from "./Complete";
import SelectType from "./SelectType";
import FormContainer, { StepDescription } from "../common/FormContainer";
import Detail from "./Detail";
import StyledButton from "../button/StyledButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectData } from "../interfaces/project.interface";
import { useQuery } from "@tanstack/react-query";
import { getCeleb } from "../../api/celebList";
import useStockCartList from "../../hooks/useStockCartList";
import { ContractFormData } from "../interfaces/contract.interface";

const ContactUs = styled.div`
  white-space: pre-wrap;
  text-align: center;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  > span > b {
    ${({ theme }) => theme.font.bold};
  }
`;

const defaultValue: ContractFormData = {
  projectId: "",
  celebId: "",
  celebAgencyId: "",
  currencyType: "USD",
  contractType: "STOCK",
  amount: 0,
  startDate: "",
  endDate: "",
  advertisingMedia: [""],
  country: [""],
  stockImage: [""],
  additionalCondition: "",
  comment: "",
};

const RequestContract = () => {
  const [step, setStep] = useState(1);
  const location = useLocation();
  const celebId = location.pathname.split("/")[2];
  const [selectedProject, setSelectedProject] = useState<ProjectData | null>(
    null
  );
  const [seedingType, setSeedingType] = useState<
    "STOCK" | "SEEDING" | "STOCK_SEEDING" | ""
  >("");
  const { stockList } = useStockCartList(celebId);
  const navigate = useNavigate();
  const { data, isPending } = useQuery({
    queryKey: [celebId, "getCelebData"],
    queryFn: () => getCeleb(celebId),
  });
  const [finalData, setFinalData] = useState<ContractFormData>(defaultValue);

  if (isPending) return <></>;
  if (!data) return <></>;

  const selectedProjectHandler = (project: ProjectData) => {
    setSelectedProject(project.id === selectedProject?.id ? null : project);
  };

  const prevStepHandler = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const nextStepHandler = () => {
    switch (step - 1) {
      case 0: {
        if (seedingType !== "") {
          setStep(step + 1);
        }
        break;
      }
      case 1: {
        if (selectedProject) {
          setStep(step + 1);
        }
        break;
      }
      case 2: {
        if (seedingType !== "") {
          setStep(step + 1);
        }
        break;
      }
      case 3: {
        if (finalData !== defaultValue || null) {
          setStep(step + 1);
        }
        break;
      }
      case 4: {
        setStep(step + 1);
        break;
      }
      default: {
        break;
      }
    }
  };

  const renderContent = (step: number) => {
    switch (step - 1) {
      case 1: {
        return (
          <>
            <StepDescription step={step} length={5}>
              Select the project that matches the contract.
            </StepDescription>
            <SelectProject
              selectedProject={selectedProject}
              selectedProjectHandler={selectedProjectHandler}
              nextStepHandler={nextStepHandler}
              prevStepHandler={prevStepHandler}
            />
          </>
        );
      }
      case 2: {
        return (
          <>
            <StepDescription step={step} length={5}>
              Please provide the contract details.
            </StepDescription>
            <Detail
              setFinalData={setFinalData}
              selectedProject={selectedProject}
              celebName={data.data.celebInfo.name}
              celebId={celebId}
              celebAgencyId={data.data.celebInfo.celebAgencyId}
              stockList={stockList}
              seedingType={seedingType !== "" ? seedingType : "SEEDING"}
              nextStepHandler={nextStepHandler}
              prevStepHandler={prevStepHandler}
            />
          </>
        );
      }
      case 3: {
        return (
          <>
            <StepDescription step={step} length={5}>
              Please review the contract details.{"\n"}
              Once you click <b>Confirm button</b>, the contract will be{"\n"}
              generated and sent to <b>Party B.</b>
            </StepDescription>
            <Review
              contractData={finalData}
              celebName={data.data.celebInfo.name}
              projectData={selectedProject}
              nextStepHandler={nextStepHandler}
              prevStepHandler={prevStepHandler}
            />
          </>
        );
      }
      case 4: {
        return (
          <>
            <StepDescription step={step} length={5}></StepDescription>
            <Complete />
          </>
        );
      }
      default: {
        return (
          <>
            <StepDescription step={step} length={5}>
              Please select the contract type.
            </StepDescription>
            <SelectType
              celebId={celebId}
              seedingType={seedingType}
              setSeedingType={setSeedingType}
              nextStepHandler={nextStepHandler}
            />
          </>
        );
      }
    }
  };

  return (
    <>
      <FormContainer title="Request Contract">
        {renderContent(step)}
      </FormContainer>
      {step === 1 ? (
        <FormContainer>
          <ContactUs>
            <span>
              Should you have any additional requests,{"\n"}
              such as <b>extra shoots, event invitations,</b>
              {"\n"}
              <b>or ambassador collaborations,</b>
              {"\n\n"}
              please feel free to let us know.
            </span>
            <StyledButton
              onClick={() => navigate("/contact-us")}
              label="Contact us"
              size="lg"
              color="white"
              style={{ marginTop: "4rem" }}
            />
          </ContactUs>
        </FormContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default RequestContract;
