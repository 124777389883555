import React, { ReactNode } from "react";
import styled from "styled-components";
import Logo from "../layout/Logo";

interface ContainerProps {
  $step?: number;
  $length?: number;
}

export const FormContainerTitle = styled.h1`
  color: var(--gray-900);
  text-align: center;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
`;

export const FormContainerStyle = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  margin: 5rem auto 0;
  width: 50rem;
  padding: 6rem 4rem;
  border: 0.1rem solid var(--gray-300);
  background: var(--white);
`;

export const StepDescriptionContainer = styled.div<ContainerProps>`
  margin-right: auto;
  .step {
    display: flex;
    width: 10rem;
    height: 0.6rem;
    border-radius: 0.3rem;
    background-color: var(--gray-300);
    margin-bottom: 1.6rem;

    .step-bar {
      width: ${(props) =>
        props.$step && props.$length
          ? (props.$step * 100) / props.$length
          : 0}%;
      height: 100%;
      border-radius: 0.3rem;
      background-color: ${(props) =>
        props.$step === props.$length
          ? "var(--purple-300)"
          : "var(--gray-700);"};
    }
  }
  .description {
    white-space: pre-wrap;
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    > b {
      ${({ theme }) => theme.font.bold};
    }
  }
`;

interface StepDescriptionProps {
  step?: number;
  length?: number;
  children?: ReactNode;
}

export const StepDescription = ({
  step,
  length,
  children,
}: StepDescriptionProps) => {
  return (
    <StepDescriptionContainer $step={step} $length={length}>
      {step !== undefined && length !== undefined && (
        <div className="step">
          <span className="step-bar" />
        </div>
      )}
      {children && <div className="description">{children}</div>}
    </StepDescriptionContainer>
  );
};

interface FormContainerProps {
  title?: string;
  logo?: boolean;
  children: React.ReactNode;
}

const FormContainer = ({ title, logo, children }: FormContainerProps) => {
  return (
    <>
      {logo && <Logo margin="6rem auto 0" />}
      {title && <FormContainerTitle>{title}</FormContainerTitle>}
      <FormContainerStyle>{children}</FormContainerStyle>
    </>
  );
};

export default FormContainer;
