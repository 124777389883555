import React from "react";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import StyledButton from "../button/StyledButton";
import useStockCartList from "../../hooks/useStockCartList";

const Wrapper = styled.div`
  width: 32rem;
`;

const Subtitle = styled.span`
  display: inline-block;
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.bold};
  margin-top: 2.4rem;
`;

const DetailText = styled.span`
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.regular};
  line-height: 130%;
  white-space: pre;
`;

const ButtonList = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const Description = styled.div`
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.regular};
  margin-top: 4rem;
  color: var(--gray-600);
`;

interface CelebSideInformationProps {
  celebData: CelebData;
  selectedIndex: number;
}

const CelebSideInformation = ({
  celebData,
  selectedIndex,
}: CelebSideInformationProps) => {
  const celebPhotoList = celebData.celebStockData;
  const celebId = celebData.celebInfo.id;
  const { addStock, removeStock } = useStockCartList(celebId);

  return (
    <Wrapper>
      <Subtitle>Photo ID : </Subtitle>
      <DetailText>{celebPhotoList[selectedIndex].photoId}</DetailText>
      <br />
      <Subtitle>Photo Details : </Subtitle>
      <br />
      <DetailText>{celebPhotoList[selectedIndex].photoDetails}</DetailText>
      <br />
      <Subtitle>Photo Contributor : </Subtitle>
      <br />
      <DetailText>{celebPhotoList[selectedIndex].photoContributor}</DetailText>
      <Description>{celebPhotoList[selectedIndex].description}</Description>
      <ButtonList>
        <StyledButton
          label="TRY"
          color="white"
          size="md"
          onClick={() => removeStock(celebPhotoList[selectedIndex])}
        />
        <StyledButton
          label="ADD"
          color="black"
          size="md"
          onClick={() => addStock(celebPhotoList[selectedIndex])}
        />
      </ButtonList>
    </Wrapper>
  );
};

export default CelebSideInformation;
