import { useQuery } from "@tanstack/react-query";
import {
  getContentList,
  getContract,
  getContractHistory,
  getContractSign,
} from "../api/contract";
import {
  ContentListSuccessResopnse,
  ContractDataResponse,
  ContractSignSuccessResponse,
} from "../components/interfaces/contract.interface";

export const useContract = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractDataResponse>({
    queryKey: ["getProjectData", id],
    queryFn: () => getContract(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useContractSign = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractSignSuccessResponse>({
    queryKey: ["getContractSign", id],
    queryFn: () => getContractSign(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useContractHistory = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractDataResponse>({
    queryKey: ["getContractHistory", id],
    queryFn: () => getContractHistory(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useContentList = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContentListSuccessResopnse>({
    queryKey: ["getContentList", id],
    queryFn: () => getContentList(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};
