import React from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const HelpIcon = () => {
  return (
    <HelpOutlineOutlinedIcon
      style={{
        color: "var(--gray-500)",
        cursor: "pointer",
        margin: "auto 0",
      }}
    />
  );
};

export default HelpIcon;
