import React from "react";
import styled from "styled-components";
import TimelineIcon from "@mui/icons-material/Timeline";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import Favorite from "../button/Favorite";
import { CelebSummaryData } from "../interfaces/celeb.interfaces";
import { formatNumber } from "../../utils/utils";
import { Link } from "react-router-dom";
import ImageContainer from "../common/ImageContainer";

const CelebCardWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1.6rem;
  width: 58rem;
  height: 30rem;
  padding: 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(71, 71, 71, 0.1);
  }
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
`;

const CelebSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CelebInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
    color: var(--gray-900);
  }
  .age {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  .type {
    color: var(--purple-300);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const CelebScoreList = styled.div`
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body1};
  color: var(--gray-900);
`;

const CelebScore = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  .name {
    ${({ theme }) => theme.font.bold};
  }
  .number {
    ${({ theme }) => theme.font.regular};
  }
`;

interface CelebCardProps {
  celebData: CelebSummaryData;
}

const CelebCard = ({ celebData }: CelebCardProps) => {
  return (
    <Link to={"/celebs/" + celebData.id} target="_self">
      <CelebCardWrapper>
        <ImageContainer width={26} height={26} borderRadius={0.4}>
          <img
            src={celebData.celebMainImg}
            alt={celebData.name + "thumbnail"}
          />
          <FavoriteWrapper>
            <Favorite id={celebData.id} likeStatus={!!celebData.isLike} />
          </FavoriteWrapper>
        </ImageContainer>
        <CelebSummary>
          <CelebInfo>
            <p className="name">{celebData.name}</p>
            <p className="age">
              {celebData.birthday}, {celebData.gender}
            </p>
            <p className="type">{celebData.keywords.join(", ")}</p>
          </CelebInfo>
          <CelebScoreList>
            <CelebScore>
              <TimelineIcon />
              <p className="name">Search</p>
              <p className="score">
                {celebData.searchVolume ? celebData.searchVolume : "N/A"}
              </p>
            </CelebScore>
            <CelebScore>
              <InstagramIcon />
              <p className="name">Followers</p>
              <p className="score">
                {formatNumber(celebData.instagramFollowersCnt)}
              </p>
            </CelebScore>
            <CelebScore>
              <PublicIcon />
              <p className="name">Popular Countries</p>
            </CelebScore>
            <CelebScore>
              <p className="score" style={{ marginLeft: "2.4rem" }}>
                {celebData.countryName.length
                  ? celebData.countryName.join(", ")
                  : "N/A"}
              </p>
            </CelebScore>
          </CelebScoreList>
        </CelebSummary>
      </CelebCardWrapper>
    </Link>
  );
};

export default CelebCard;
