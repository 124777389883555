export const getCelebData = () => {
  return Response;
};

export const getHighlightData = () => {
  return HighlightResponse;
};

const HighlightResponse = {
  ok: true,
  statusCode: 200,
  message: "SUCCESS",
  data: [
    {
      id: "031a5297-6067-4896-8429-311128f4c3be",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name1",
      movieReleaseDate: "2024-06-17T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "0e3cde93-d644-4886-866b-e52626078f33",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name2",
      movieReleaseDate: "2024-05-31T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "3488a60e-22a8-44cb-83f8-0575f93d034d",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name3",
      movieReleaseDate: "2024-04-22T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "5bb6629d-71c3-48ea-b453-63e26a70e178",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name4",
      movieReleaseDate: "2021-06-22T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "73fdd08b-42f6-4f1b-b836-748a2bc9dc57",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name5",
      movieReleaseDate: "2024-12-14T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "80780a07-7d12-47f3-9299-81d997276635",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name6",
      movieReleaseDate: "2024-05-05T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "97609fb4-b879-4dcf-9b9d-e62c6a1a3d4b",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name7",
      movieReleaseDate: "2024-04-16T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "cc1d47cc-be2f-4f18-b869-86bd813ee62f",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name8",
      movieReleaseDate: "2024-05-31T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "d7e8f36c-a85f-495a-b157-f62a86243ae7",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name9",
      movieReleaseDate: "2016-06-09T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
    {
      id: "ee9b5e35-cb88-453a-9bc8-0397408ecc39",
      imgUrl:
        "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
      movieName: "move_name10",
      movieReleaseDate: "2022-05-31T15:00:00.000Z",
      celebId: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      templateLogoId: "80064e44-d3cf-4025-9a50-151796a6a0e6",
      logoImg:
        "https://celeb-image.s3.ap-northeast-2.amazonaws.com/template_img/netflix.png",
    },
  ],
};

const Response = {
  ok: true,
  statusCode: 200,
  message: "SUCCESS",
  data: {
    celebInfo: {
      id: "08bb47af-3f1c-4893-80f1-502e5220a52f",
      name: "이주빈",
      activeFrom: 2017,
      management: "Lorem Ipsum management",
      webUrl: "webUrl",
      instaUrl: "https://www.instagram.com/hellobeen/",
      youtubeUrl: "youtubeUrl",
      tag: [],
      keyword: ["Actress", "Musician"],
    },
    celebStockData: [
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/01.jpg",
        photoId: "123-1123",
        photoDetails:
          "JPEG, PNG,\nMax size : 10,000 x 18,000 px\n300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/02.jpg",
        photoId: "345-12341234",
        photoDetails:
          "JPEG, PNG,\n Max size : 10,000 x 18,000 px\n 300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/03.png",
        photoId: "213-2349087",
        photoDetails:
          "JPEG, PNG,\n Max size : 10,000 x 18,000 px\n 300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/04.png",
        photoId: "1234-45",
        photoDetails:
          "JPEG, PNG,\n Max size : 10,000 x 18,000 px\n 300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/kalebo_okoroy/05.png",
        photoId: "345345-345234",
        photoDetails:
          "JPEG, PNG,\n Max size : 10,000 x 18,000 px\n 300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
      {
        imgUrl:
          "https://nataltek.com/celebrity/images/celeb/amala_nduku/01.png",
        photoId: "235-236236",
        photoDetails:
          "JPEG, PNG,\n Max size : 10,000 x 18,000 px\n 300 DPI, 10mb",
        photoContributor: "THE BLUE LABEL",
        description: "hello",
      },
    ],
    searchTrend: {
      beforeTotalVolume: null,
      totalVolume: 90500,
      topSearchPercent: [
        {
          percentage: 53.43505,
          search_volume: 10701,
          countryIsoCode: "KR",
        },
        {
          percentage: 20.5346,
          search_volume: 4112,
          countryIsoCode: "US",
        },
        {
          percentage: 6.99036,
          search_volume: 1400,
          countryIsoCode: "JP",
        },
        {
          percentage: 3.42236,
          search_volume: 685,
          countryIsoCode: "TW",
        },
        {
          percentage: 1.89863,
          search_volume: 380,
          countryIsoCode: "TH",
        },
      ],
    },
    instagram: {
      instagramName: "hellobeen",
      celebMainImg:
        "https://cdn.hypeauditor.com/img/instagram/user/2312174376.jpg?w=320&till=1730419140&sign=0de0cb496f1307524539e31bc17db54e",
      followers: 1877061,
      postsCount: 726,
      realFollowers: "71.84",
      followings: 174,
      avgLikes: 170511,
      avgComments: 266,
      impressions: 328553,
      egr: 0.74,
      emvFrom: 350000,
      emvTo: 1500000,
      audienceGender: [
        {
          value: 30.22,
          gender: "M",
        },
        {
          value: 69.76,
          gender: "F",
        },
      ],
      audienceAge: [
        {
          value: 49.22,
          gender: "male",
          byAgeGroup: [
            {
              group: "age13-17",
              value: 5.58,
            },
            {
              group: "age18-24",
              value: 16.19,
            },
            {
              group: "age25-34",
              value: 21.31,
            },
            {
              group: "age35-44",
              value: 3.93,
            },
            {
              group: "age45-54",
              value: 1.36,
            },
            {
              group: "age55-64",
              value: 0.63,
            },
            {
              group: "age65-",
              value: 0.22,
            },
          ],
        },
        {
          value: 50.76,
          gender: "female",
          byAgeGroup: [
            {
              group: "age13-17",
              value: 7.23,
            },
            {
              group: "age18-24",
              value: 22.37,
            },
            {
              group: "age25-34",
              value: 17.6,
            },
            {
              group: "age35-44",
              value: 1.66,
            },
            {
              group: "age45-54",
              value: 0.94,
            },
            {
              group: "age55-64",
              value: 0.53,
            },
            {
              group: "age65-",
              value: 0.43,
            },
          ],
        },
      ],
      audienceGeo: [
        {
          code: "KR",
          name: "South Korea",
          value: 69.64,
        },
        {
          code: "US",
          name: "United States (USA)",
          value: 4.5,
        },
        {
          code: "ID",
          name: "Indonesia",
          value: 3.47,
        },
        {
          code: "JP",
          name: "Japan",
          value: 3.28,
        },
        {
          code: "PH",
          name: "Philippines",
          value: 2.05,
        },
      ],
      followersChart: [
        {
          date: "2024.09.01",
          count: 1836020,
        },
        {
          date: "2024.08.01",
          count: 1830132,
        },
        {
          date: "2024.07.01",
          count: 1773466,
        },
        {
          date: "2024.06.01",
          count: 1671270,
        },
        {
          date: "2024.05.01",
          count: 1366710,
        },
        {
          date: "2024.04.01",
          count: 758096,
        },
        {
          date: "2024.03.01",
          count: 731402,
        },
        {
          date: "2024.02.01",
          count: 733715,
        },
        {
          date: "2024.01.01",
          count: 735737,
        },
        {
          date: "2023.12.01",
          count: 733827,
        },
        {
          date: "2023.11.01",
          count: 736402,
        },
        {
          date: "2023.10.01",
          count: 737480,
        },
        {
          date: "2023.09.01",
          count: 738626,
        },
        {
          date: "2023.07.01",
          count: 740224,
        },
        {
          date: "2023.05.01",
          count: 738160,
        },
        {
          date: "2023.04.01",
          count: 739730,
        },
      ],
    },
  },
};
