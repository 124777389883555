import React from "react";
import CelebCard from "./CelebCard";
import styled from "styled-components";
import { CelebSummaryData } from "../interfaces/celeb.interfaces";

const CelebCardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 30rem;
  gap: 1.6rem;
`;

interface CelebCardListProps {
  celebList: CelebSummaryData[];
}

const CelebCardList = ({ celebList }: CelebCardListProps) => {
  return (
    <CelebCardListContainer>
      {celebList.map((data, index) => {
        return <CelebCard key={data.id + index} celebData={data} />;
      })}
    </CelebCardListContainer>
  );
};

export default CelebCardList;
