import React from "react";
import styled from "styled-components";
import StyledButton from "../../button/StyledButton";
import { useContractStatus } from "../../../hooks/useContractStatus";
import { ContractSign } from "../../interfaces/contract.interface";
import { CONTRACT_STATUS_PRESETS } from "../../../configs/contractStatus";

const Container = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const SignatureBoxContianer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const SignatureBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  > .company {
    text-align: left;
    width: 50%;
    color: var(--gray-900);
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
  }
  > .signature {
    color: var(--gray-600);
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.regular};
    width: 50%;
    overflow: hidden;
    text-align: center;
    > img {
      width: 100%;
      height: 6rem;
      object-fit: contain;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
`;

interface SignatureConfirmProps {
  signData: ContractSign;
  contractId: string;
  refetch: () => void;
}

const SignatureConfirm = ({
  signData,
  contractId,
  refetch,
}: SignatureConfirmProps) => {
  const { updateStatus } = useContractStatus(contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <Container>
      <SignatureBoxContianer>
        <SignatureBox>
          <div className="company">{signData.celeb.name}</div>

          {signData.contractSign.length ? (
            <div className="signature">
              <img
                src={signData.contractSign[0].signImageUrl}
                alt={signData.celeb.name + "signature"}
              />
            </div>
          ) : (
            <div className="signature">Signature</div>
          )}
        </SignatureBox>
      </SignatureBoxContianer>
      <div style={{ width: "100%", borderTop: "0.4rem solid var(--black)" }} />
      <ButtonContainer>
        <StyledButton
          size="sm"
          color="white"
          label="Re-request"
          onClick={() =>
            updateStatus(CONTRACT_STATUS_PRESETS.AD_RE_REQUEST_CELEB_REQUEST)
          }
        />
        <StyledButton
          size="sm"
          color="black"
          label="Confirm"
          onClick={() => updateStatus(CONTRACT_STATUS_PRESETS.UPLAOD_MODIFY)}
        />
      </ButtonContainer>
    </Container>
  );
};

export default SignatureConfirm;
