import React, { ReactNode } from "react";
import styled from "styled-components";

interface SectionProps {
  bgColor?: string;
  bgUrl?: string;
  margin?: string;
  ml?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  padding?: string;
  pl?: string;
  pt?: string;
  pr?: string;
  pb?: string;
  border?: string;
  bl?: string;
  bt?: string;
  br?: string;
  bb?: string;
  children?: ReactNode;
  [key: string]: any;
}

const SectionStyle = styled.section<SectionProps>`
  ${(props) => (props.$bgColor ? `background-color: ${props.$bgColor}` : "")};
  ${(props) => (props.$bgUrl ? `background-image: url(${props.$bgUrl})` : "")};
  ${(props) => (props.$bgUrl ? "background-repeat: no-repeat" : "")};
  ${(props) => (props.$bgUrl ? "background-position: center top" : "")};
  ${(props) => (props.$bgUrl ? "background-size: cover" : "")};
  ${(props) => (props.$margin ? `margin: ${props.$margin}` : "")};
  ${(props) => (props.$ml ? `margin-left: ${props.$ml}` : "")};
  ${(props) => (props.$mt ? `margin-top: ${props.$mt}` : "")};
  ${(props) => (props.$mr ? `margin-right: ${props.$mr}` : "")};
  ${(props) => (props.$mb ? `margin-bottom: ${props.$mb}` : "")};
  ${(props) => (props.$padding ? `padding: ${props.$padding}` : "")};
  ${(props) => (props.$pl ? `padding-left: ${props.$pl}` : "")};
  ${(props) => (props.$pt ? `padding-top: ${props.$pt}` : "")};
  ${(props) => (props.$pr ? `padding-right: ${props.$pr}` : "")};
  ${(props) => (props.$pb ? `padding-bottom: ${props.$pb}` : "")};
  ${(props) => (props.$border ? `border: ${props.$border}` : "")};
  ${(props) => (props.$bl ? `border-left: ${props.$bl}` : "")};
  ${(props) => (props.$bt ? `border-top: ${props.$bt}` : "")};
  ${(props) => (props.$br ? `border-right: ${props.$br}` : "")};
  ${(props) => (props.$bb ? `border-bottom: ${props.$bb}` : "")};
`;

const Contents = styled.div`
  width: 100%;
  margin: 0 auto;
  ${({ theme }) => theme.media.sm} {
    width: 100%;
  }
  ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  ${({ theme }) => theme.media.lg} {
    width: 100%;
  }
  ${({ theme }) => theme.media.xl} {
    width: 144rem;
  }
`;

const Section = ({
  bgColor,
  bgUrl,
  margin,
  ml,
  mt,
  mr,
  mb,
  padding,
  pl,
  pt,
  pr,
  pb,
  border,
  bl,
  bt,
  br,
  bb,
  children,
  ...options
}: SectionProps) => {
  return (
    <SectionStyle
      $bgColor={bgColor}
      $bgUrl={bgUrl}
      $margin={margin}
      $ml={ml}
      $mt={mt}
      $mr={mr}
      $mb={mb}
      $padding={padding}
      $pl={pl}
      $pt={pt}
      $pr={pr}
      $pb={pb}
      $border={border}
      $bl={bl}
      $bt={bt}
      $br={br}
      $bb={bb}
      {...options}
    >
      <Contents>{children}</Contents>
    </SectionStyle>
  );
};

export default Section;
