import React from "react";
import styled from "styled-components";

interface ChartProps {
  $data: ProcessedAudienceGender[];
}

interface ProcessedAudienceGender {
  value: number;
  gender: string;
  color: string;
  deg: number;
}

interface GenderProps {
  color: string;
}

const Chart = styled.span<ChartProps>`
  width: 14rem;
  margin: 0 auto;
  .chart {
    position: relative;
    width: 14rem;
    height: 14rem;
    border-radius: 50%;
    transition: 0.3s;
    background: lightgray;
    display: inline-block;
    .chart-bar {
      width: inherit;
      height: inherit;
      border-radius: 50%;
      background: ${(props) => {
        let gradientParts: string[] = [];
        let currentAngle = 0;

        props.$data.forEach((item) => {
          const nextAngle = currentAngle + item.deg;
          gradientParts.push(
            `${item.color} ${currentAngle}deg ${nextAngle}deg`
          );
          currentAngle = nextAngle;
        });

        if (currentAngle < 360) {
          gradientParts.push(`lightgray ${currentAngle}deg 360deg`);
        }

        return `conic-gradient(${gradientParts.join(", ")})`;
      }};
      &::after {
        content: "";
        background: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

const Legend = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /*gap: 1.6rem;*/
  justify-content: space-between;
  margin-top: 0.8rem;
`;

const Gender = styled.span<GenderProps>`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  &::before {
    display: inline-block;
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    margin-right: 0.8rem;
  }
`;

interface AudienceGender {
  value: number;
  gender: string;
}

interface AudienceChartProps {
  data: AudienceGender[];
}

const genderColorMap: { [key: string]: string } = {
  M: "#8BC6FF",
  F: "#F35F8D",
  //NonBinary: "#FFD700",
  //Other: "#ADFF2F",
};

const AudienceGenderChart = ({ data }: AudienceChartProps) => {
  const processedData: ProcessedAudienceGender[] = data.map((item) => ({
    value: item.value,
    gender: item.gender,
    color: genderColorMap[item.gender],
    deg: (360 / 100) * item.value,
  }));

  return (
    <>
      <Chart $data={processedData}>
        <div className="chart">
          <div className="chart-bar"></div>
        </div>
      </Chart>
      <Legend>
        {processedData.map((data, index) => {
          return (
            <Gender key={"genderchart" + index} color={data.color}>
              {data.gender === "M" ? "Male" : "Female"} {data.value.toFixed(1)}%
            </Gender>
          );
        })}
      </Legend>
    </>
  );
};

export default AudienceGenderChart;
