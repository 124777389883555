import { useAtomValue } from "jotai";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { userTypeAtom } from "../../atoms/auth";

const SidebarContainer = styled.div`
  width: 32rem;
  background-color: var(--white);
  color: var(--gray-800);
  padding: 2rem;
  height: 100vh;
  flex-shrink: 0;
`;

const SidebarTitle = styled.div`
  padding: 4rem 2rem 2rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  > .icon {
    width: 4rem;
    height: 4rem;
    background-color: var(--gray-300);
    border-radius: 50%;
  }
  > .title {
    ${({ theme }) => theme.font.regular};
    ${({ theme }) => theme.font.title1};
    color: var(--gray-800);
  }
  &.border-top {
    border-top: 0.1rem solid var(--gray-800);
    padding: 2rem;
  }
`;

const SidebarList = styled.ul`
  border-top: 0.1rem solid var(--gray-800);
  padding: 3.2rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  min-height: 42rem;
`;

const SidebarItem = styled.li`
  ${({ theme }) => theme.font.regular};
  ${({ theme }) => theme.font.title2};
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const SubItem = styled(NavLink)`
  ${({ theme }) => theme.font.body1};
  padding-left: 1.6rem;
  cursor: pointer;
  &:hover {
    /*${({ theme }) => theme.font.bold};*/
    color: var(--primary);
  }
  &.active {
    ${({ theme }) => theme.font.bold};
  }
`;

const AdvertiserMenu = () => {
  return (
    <>
      <SidebarItem>
        Project management
        <SubItem to="projects/list?type=ongoing">Project management</SubItem>
        {/*<SubItem to="projects/create">Create new projects</SubItem>
        <SubItem to="projects/list/ongoing">Ongoing projects</SubItem>
        <SubItem to="projects/list/past">Past projects</SubItem>*/}
      </SidebarItem>
      <SidebarItem>
        Celebrity management
        <SubItem to="celebs/contract">Celebs in contract</SubItem>
        <SubItem to="celebs/favorite">Favorite Celebrities</SubItem>
        {/*<SubItem to="celebs/past">Celebs in past project</SubItem>*/}
      </SidebarItem>
    </>
  );
};

const CelebAgencyMenu = () => {
  return (
    <>
      <SidebarItem>
        Celebrity management
        <SubItem to="celebs/list?type=active">Celebs in contract</SubItem>
        <SubItem to="celebs/my-celebs">Celebrities on celevu</SubItem>
        {/*<SubItem to="celebs/past">Celebs in past project</SubItem>*/}
      </SidebarItem>
    </>
  );
};

const Sidebar = () => {
  const userType = useAtomValue(userTypeAtom);

  return (
    <SidebarContainer>
      <SidebarTitle>
        <div className="icon" />
        <div className="title">Manage</div>
      </SidebarTitle>
      <SidebarList>
        {userType === "advertiser" && <AdvertiserMenu />}
        {userType === "celebrity_agency" && <CelebAgencyMenu />}
      </SidebarList>
      <SidebarTitle className="border-top">
        <div className="icon" />
        <div className="title">Account Setting</div>
      </SidebarTitle>
    </SidebarContainer>
  );
};

export default Sidebar;
