import { atom, getDefaultStore } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { UserInformation } from "../components/interfaces/auth.interfaces";

export const accessTokenAtom = atom<string | null>(null);
export const refreshTokenAtom = atom<string | null>(null);
export const userTypeAtom = atom<string | null>(null);

export const userDataAtom = atomWithStorage<UserInformation | null>(
  "user",
  null
);

export const updateTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem("accessToken", accessToken);
  localStorage.setItem("refreshToken", refreshToken);

  const store = getDefaultStore();
  store.set(accessTokenAtom, accessToken);
  store.set(refreshTokenAtom, refreshToken);
};

export const getTokens = () => {
  const store = getDefaultStore();

  let accessToken = store.get(accessTokenAtom);
  let refreshToken = store.get(refreshTokenAtom);
  if (!accessToken) {
    accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      store.set(accessTokenAtom, accessToken);
    }
  }

  if (!refreshToken) {
    refreshToken = localStorage.getItem("refreshToken");
    if (refreshToken) {
      store.set(refreshTokenAtom, refreshToken);
    }
  }
  return { accessToken, refreshToken };
};

export const clearSession = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");

  const store = getDefaultStore();
  store.set(accessTokenAtom, null);
  store.set(refreshTokenAtom, null);
  store.set(userDataAtom, null);
};
