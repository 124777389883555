import React from "react";
import styled from "styled-components";
import { FilterQuery } from "../celeb/CelebList";

const OrderSelect = styled.select`
  width: 25.6rem;
  height: 4rem;
  padding: 1rem 2rem;
  border: 0.1rem solid var(--purple-300);
  border-radius: 2rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const orderList = [
  { value: "search_volume", displayName: "Search volume" },
  { value: "follower", displayName: "Instagram followers" },
];

interface CountryProps {
  query: FilterQuery;
  setQuery: Function;
}

const Order = ({ query, setQuery }: CountryProps) => {
  return (
    <OrderSelect
      id="order-select"
      name="order__by"
      value={query.order__by}
      onChange={(e) => setQuery("order__by", e.target.value)}
    >
      {orderList.map((order, index) => {
        return (
          <option key={"select_option_" + order.value} value={order.value}>
            {order.displayName}
          </option>
        );
      })}
    </OrderSelect>
  );
};

export default Order;
