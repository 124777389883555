export const theme = {
  media: {
    sm: "@media screen and (min-width: 480px)",
    md: "@media screen and (min-width: 768px)",
    lg: "@media screen and (min-width: 1024px)",
    xl: "@media screen and (min-width: 1440px)",
  },
  font: {
    inter: `
      font-family: Inter`,
    larken: `
      font-family: larken;`,
    bold: `
      font-weight: 700;`,
    regular: `
      font-weight: 400;`,
    italic: `
      font-style: italic`,
    underline: `
      text-decoration: underline`,
    h1: `
      font-size: 4rem;
      line-height: 120%;`,
    h2: `
      font-size: 3.6rem;
      line-height: 120%;`,
    h3: `
      font-size: 3.2rem;
      line-height: 120%;`,
    h4: `
      font-size: 2.8rem;
      line-height: 120%;`,
    title1: `
      font-size: 2.4rem;
      line-height: 120%`,
    title2: `
      font-size: 2rem;
      line-height: 120%`,
    title3: `
      font-size: 1.6rem;
      line-height: 120%`,
    body1: `
      font-size: 1.6rem;
      line-height: 120%;`,
    body2: `
      font-size: 1.4rem;
      line-height: 120%;`,
    body3: `
      font-size: 1.2rem;
      line-height: 120%;`,
    body4: `
      font-size: 1rem;
      line-height: 120%;`,
  },
};
