import React from "react";
import styled from "styled-components";
import { AudienceGeo } from "../interfaces/celeb.interfaces";

interface ChartProps {
  $deg: number;
  $color: string;
}

const Chart = styled.span<ChartProps>`
  width: 8rem;
  .chart {
    position: relative;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    transition: 0.3s;
    background: lightgray;
    display: inline-block;
    .chart-bar {
      width: inherit;
      height: inherit;
      border-radius: 50%;
      background: ${(props) =>
        `conic-gradient(${props.$color} ${props.$deg}deg, lightgray ${props.$deg}deg)`};
      &:hover {
      }
    }
    .chart-number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      ${({ theme }) => theme.font.body1}
      ${({ theme }) => theme.font.regular}
    }
  }
  .chart:after {
    content: "";
    background: #fff; /* 백그라운드 컬러로 중앙가리기 */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  .country-name {
    ${({ theme }) => theme.font.body3}
    ${({ theme }) => theme.font.regular}
    text-align: center;
  }
`;

interface AudienceChartProps {
  //data: AudienceGeo;
  name: string;
  value: number;
  color: string;
}

const AudienceGeoChart = ({ name, value, color }: AudienceChartProps) => {
  const result = (360 / 100) * value;
  return (
    <>
      <Chart $deg={result} $color={color}>
        <div className="chart">
          <div className="chart-bar"></div>
          <span className="chart-number">{value.toFixed(1)}%</span>
        </div>
        <p className="country-name">{name}</p>
      </Chart>
    </>
  );
};

export default AudienceGeoChart;
