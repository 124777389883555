import React from "react";
import FormContainer from "../common/FormContainer";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import StyledButton from "../button/StyledButton";
import { useNavigate } from "react-router-dom";
import RegisterInput from "../input/RegisterInput";
import MediaType from "./AdvertisingMedia";
import AdvertisingCountry from "./AdvertisingCountry";
import RegisterTextArea from "../input/RegisterTextArea";
import Period from "./Period";
import Currency from "./Currency";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  currencyType: z.string().min(1, "Currency type is required"),
  amount: z.number().min(1, "Amount is required"),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  country: z.array(z.string()).min(1, "At least one country must be selected"),
  advertisingMedia: z
    .array(z.string())
    .min(1, "At least one media type must be selected"),
});

const ProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background-color: var(--gray-100);
  padding: 2rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  .bold {
    ${({ theme }) => theme.font.bold};
  }
  .margin-top {
    margin-top: 2rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

const CreateContract = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ContractFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      //projectId: projectId,
      //celebAgencyId: celebAgencyId,
      //celebId: CelebSideInformation,
      //currencyType: currencyType,
      //contractType: contractType,
      amount: 0,
      startDate: today,
      endDate: "",
      country: [],
      advertisingMedia: [],
      additionalCondition: "",
      comment: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const onSubmit = (data: ContractFormData) => {
    console.log(data);
  };

  const advertisingMedia = watch("advertisingMedia");
  const country = watch("country");
  const dates = watch(["startDate", "endDate"]);
  const currencyType = watch("currencyType");

  return (
    <FormContainer title="Modify contract">
      <ProjectInformation>
        <div className="bold">title</div>
        <div>description</div>
        <div className="bold margin-top">name</div>
        <div>photos, Social Media Seeding</div>
      </ProjectInformation>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Currency
          register={register}
          name="amount"
          setValue={setValue}
          currencyType={currencyType}
          errorMessage={{
            amount: errors.amount?.message,
            currencyType: errors.currencyType?.message,
          }}
        />
        <Period
          setValue={setValue}
          label="Contract period"
          value={{
            startDate: dates[0],
            endDate: dates[1],
          }}
          errorMessage={{
            startDate: errors.startDate?.message,
            endDate: errors.endDate?.message,
          }}
        />
        <AdvertisingCountry
          value={country}
          setValue={setValue}
          errorMessage={errors.country?.message}
        />
        <MediaType
          value={advertisingMedia}
          setValue={setValue}
          errorMessage={errors.advertisingMedia?.message}
        />
        <RegisterTextArea
          register={register}
          fieldName="additionalCondition"
          label="Additional condition"
          placeholder="Please enter any additional negotiation details regarding the contract."
        />
        <RegisterInput
          register={register}
          label="Comments"
          fieldName="comment"
        />
        <ButtonContainer>
          <StyledButton
            type="button"
            label="Previous"
            color="white"
            size="sm"
            onClick={() => navigate(-1)}
          />
          <StyledButton
            type="submit"
            label="Complete"
            color="black"
            size="sm"
          />
        </ButtonContainer>
      </Form>
    </FormContainer>
  );
};

export default CreateContract;
