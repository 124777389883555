import React, { ReactNode } from "react";
import styled from "styled-components";
import Logo from "../layout/Logo";
import { userType } from "../interfaces/auth.interfaces";
import SignatureUpload from "./celebrityAgency/SignatureUpload";
import { useContractSign } from "../../hooks/useContract";
import SignatureConfirm from "./advertiser/SignatureConfirm";

const SignatureFormContainer = styled.div`
  width: 100%;
  border: 0.1rem solid var(--gray-300);
  padding: 6rem 4rem;
  background-color: var(--white);
  gap: 4rem;
`;

const FormContainer = styled.div`
  width: 42rem;
  margin: 0 auto;
`;

const Title = styled.div`
  width: 50%;
  margin-right: auto;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--gray-900);
`;

const ContractSection = styled.div`
  width: 100%;
  b {
    ${({ theme }) => theme.font.bold};
  }
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Flex = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Agreement = styled.div`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-900);
  white-space: pre-wrap;
  text-align: left;
  margin-right: auto;
  margin-top: 4rem;
`;
interface SignatureFormProps {
  contractId: string;
  userType: userType | undefined;
  refetch: () => void;
}
const SignatureForm = ({
  contractId,
  userType,
  refetch,
}: SignatureFormProps) => {
  const { data: signData } = useContractSign(contractId);

  if (!signData) return <></>;
  return (
    <SignatureFormContainer>
      <FormContainer>
        <Logo width="14rem" height="6rem" margin="0 auto 4rem 0" />
        <ContractSection>
          <Title>Contractors</Title>
          <FlexColumn>
            <Flex>
              <b>Party A</b>
              <span>{signData?.data.advertiser.companyName}</span>
            </Flex>
            <Flex>
              <b>Party B</b>
              <span>{signData?.data.celeb.name}</span>
            </Flex>
          </FlexColumn>
          <div
            style={{
              width: "100%",
              borderTop: "0.4rem solid var(--gray-900)",
              marginTop: "4rem",
            }}
          />
          <Agreement>
            This contract defines the terms and conditions{"\n"}
            between the parties, detailing roles, responsibilities,{"\n"}
            payment terms, and duration. It also covers{"\n"}
            confidentiality, dispute resolution, and termination{"\n"}
            clauses. By signing, both parties agree to the stated{"\n"}
            terms and their obligations.
          </Agreement>
        </ContractSection>
        {userType === "advertiser" && (
          <SignatureConfirm
            signData={signData.data}
            contractId={contractId}
            refetch={refetch}
          />
        )}
        {userType === "celebrity_agency" && (
          <SignatureUpload
            signData={signData.data}
            contractId={contractId}
            refetch={refetch}
          />
        )}
      </FormContainer>
    </SignatureFormContainer>
  );
};

export default SignatureForm;
