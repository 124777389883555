import React from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import styled from "styled-components";
import { ErrorMessage, LabelGroup, Label } from "../input/ContractInput";
import { currencyList } from "../../utils/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const CurrencyContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const CurrencySelect = styled.select`
  width: 9.2rem;
  height: 6rem;
  padding: 1.2rem 2rem;
  border: 0.1rem solid var(--gray-300);
  border-radius: 0.4rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  appearance: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .currency-input {
    width: 32rem;
    height: 6rem;
    padding: 2rem 5rem 2rem 2rem;
    border: 0.1rem solid var(--gray-300);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
    text-align: right;
  }
  .sign {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

const getCurrencyByValue = (value: string) => {
  return currencyList.find((currency) => currency.value === value) || null;
};

const formatWithCommas = (value: number) => {
  return value.toLocaleString();
};

interface CurrencyProps {
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  currencyType: string;
  errorMessage?: {
    amount?: string;
    currencyType?: string;
  };
}

const Currency = ({
  setValue,
  register,
  name,
  currencyType,
  errorMessage,
}: CurrencyProps) => {
  const selectedCurrency = getCurrencyByValue(currencyType);
  const selectedSign = selectedCurrency ? selectedCurrency.sign : "";
  const isCurrencyType = (
    value: string
  ): value is ContractFormData["currencyType"] => {
    return currencyList.some((currency) => currency.value === value);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e);
    if (isCurrencyType(e.target.value)) {
      setValue("currencyType", e.target.value);
    }
  };

  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>Amount</Label>
        {errorMessage?.amount && (
          <ErrorMessage>{errorMessage.amount}</ErrorMessage>
        )}
        {errorMessage?.currencyType && (
          <ErrorMessage>{errorMessage.currencyType}</ErrorMessage>
        )}
      </LabelGroup>
      <CurrencyContainer>
        <CurrencySelect value={currencyType} onChange={handleCurrencyChange}>
          {currencyList.map((currency) => (
            <option key={currency.id} value={currency.value}>
              {currency.label}
            </option>
          ))}
        </CurrencySelect>
        <InputContainer>
          <input
            className="currency-input"
            type="text"
            {...register(name, {
              setValueAs: (value: any) => {
                if (typeof value === "number") return value;
                if (typeof value !== "string") return 0;
                return Number(value.replace(/,/g, ""));
              },
              onChange: (e) => {
                const value = e.target.value.replace(/,/g, "");
                if (!isNaN(Number(value))) {
                  e.target.value = formatWithCommas(Number(value));
                }
              },
            })}
          />
          <span className="sign">{selectedSign}</span>
        </InputContainer>
      </CurrencyContainer>
    </Container>
  );
};

export default Currency;
