import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import NavBack from "./NavBack";
import Modals from "../../modals/Modals";

interface LayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Modals />
      <Navbar />
      {children}
    </>
  );
};

export const LayoutWithNavBack = ({ children }: LayoutProps) => {
  return (
    <>
      <Modals />
      <NavBack />
      {children}
    </>
  );
};

export const LayoutWithOutNavbar = ({ children }: LayoutProps) => {
  return (
    <>
      <Modals />
      {children}
    </>
  );
};

export default Layout;
