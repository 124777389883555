import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { JSX, useEffect } from "react";
import { useAtom } from "jotai";
import { userTypeAtom } from "../atoms/auth";

interface RouteProtectProps {
  children: JSX.Element;
}

const RouteProtect = ({ children }: RouteProtectProps): JSX.Element => {
  const { isAuthenticated, isPending, user } = useAuth();
  const [, setUserType] = useAtom(userTypeAtom);

  useEffect(() => {
    if (user) {
      setUserType(user.companyType);
    } else {
      setUserType(null);
    }
  }, [user, setUserType]);

  if (isPending) return <div></div>;

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export default RouteProtect;
