import React from "react";
import { UseFormRegister } from "react-hook-form";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DateInput = styled.input`
  font-family: "inter";
  border: 0.1rem solid var(--gray-300);
  border-radius: 0.4rem;
  background-color: var(--white);
  color: var(--gray-900);
  width: 21rem;
  height: 6rem;
  padding: 2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
`;

const RangeText = styled.div`
  color: var(--gray-900);
  text-align: center;

  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const LabelGroup = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
`;

const Label = styled.label<{ $required?: boolean }>`
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};

  &::after {
    content: ${({ $required }) => ($required ? "'*'" : "''")};
    color: var(--crimson-600);
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ErrorMessage = styled.p`
  color: var(--crimson-600);
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  text-overflow: ellipsis;
`;
interface ContractPeriodProps {
  register: UseFormRegister<any>;
  errorMessage: string;
}

const ContractPeriod = ({ register, errorMessage }: ContractPeriodProps) => {
  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>Contract period</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <InputContainer>
        <DateInput
          type="date"
          {...register("startDate", { required: "Start date is required" })}
        />
        <DateInput
          type="date"
          {...register("endDate", { required: "End date is required" })}
        />
      </InputContainer>
      <RangeText>Ad duration :{}</RangeText>
    </Container>
  );
};

export default ContractPeriod;
