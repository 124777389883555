import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useFormContext,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SignupFormData } from "../auth/SignupForm";
import { AxiosError } from "axios";
import { getVerifyStatus, postVerifyEmail } from "../../api/auth";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const LabelGroup = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
`;

const Label = styled.label<{ required: boolean }>`
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};

  &::after {
    content: ${({ required }) => (required ? "'*'" : "''")};
    color: var(--crimson-600);
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ErrorMessage = styled.p`
  color: var(--crimson-600);
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Input = styled.input`
  border-radius: 0.4rem;
  color: var(--gray-600);
  border: 0.1rem solid var(--gray-300);
  width: 42rem;
  height: 6rem;
  padding: 2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  &.error {
    border: 0.1rem solid var(--crimson-600);
  }
`;

interface SignupInputProps {
  label: string;
  name: string;
  placeholder?: string;
  register: UseFormRegister<any>;
  errorMessage?: string;
  setError: UseFormSetError<SignupFormData>;
  required?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const Verify = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  color: var(--gray-600);
  border-radius: 15px;
  padding: 0.6rem 1.2rem;
  border: 0.1rem solid var(--gray-300);
  background: var(--gray-050);
`;

interface SignupErrorResponse {
  ok: boolean;
  statusCode: number;
  data: Array<{
    field: string;
    messages: string[];
  }>;
}

interface VerifySendSuccessResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: {
    data: {
      $metadata: {
        httpStatusCode: number;
        requestId: string;
        attempts: number;
        totalRetryDelay: number;
      };
      MessageId: string;
    };
    token: string;
  };
}

interface VerifyStatusResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: number;
}

export const EmailInput = ({
  label,
  name,
  placeholder,
  register,
  errorMessage,
  setError,
  required = false,
  inputProps,
}: SignupInputProps) => {
  const { watch } = useFormContext();
  const inputValue = watch(name);
  const [isPolling, setIsPolling] = useState(false);
  const [verified, setVerified] = useState(false);
  const [emailToVerify, setEmailToVerify] = useState<string>("");

  const { data } = useQuery<VerifyStatusResponse>({
    queryKey: ["emailVerification", emailToVerify],
    queryFn: () => getVerifyStatus(emailToVerify),
    refetchInterval: isPolling ? 50000 : false,
    enabled: isPolling,
  });

  useEffect(() => {
    if (data && data.ok) {
      setIsPolling(false);
      setVerified(true);
    }
  }, [data]);

  const mutation = useMutation<
    VerifySendSuccessResponse,
    AxiosError<SignupErrorResponse>,
    string
  >({
    mutationFn: (email: string) => postVerifyEmail(email),
    onSuccess: (data) => {
      setIsPolling(true);
    },
    onError: (error) => {
      const response = error.response?.data;
      if (response && response.data) {
        setError("email", {
          type: "server",
          message: response.data[0].messages[0],
        });
      }
    },
  });

  const onClickVerify = (email: string) => {
    setEmailToVerify(email);
    mutation.mutate(email);
  };

  return (
    <InputContainer>
      <LabelGroup>
        <Label required={required}>{label}</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <div style={{ position: "relative" }}>
        <Input
          {...register(name)}
          type="email"
          className={errorMessage ? "error" : ""}
          placeholder={placeholder}
          {...inputProps}
        />
        <Verify
          type="button"
          onClick={() => onClickVerify(inputValue)}
          tabIndex={-1}
        >
          {verified ? "Verified" : "Verify"}
        </Verify>
      </div>
    </InputContainer>
  );
};
