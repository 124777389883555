import React from "react";
import { Controller, Control } from "react-hook-form";
import styled from "styled-components";
import { ErrorMessage, LabelGroup, Label } from "../input/ContractInput";
import { currencyList } from "../../utils/utils";

const getCurrencyByValue = (value: string) => {
  return currencyList.find((currency) => currency.value === value) || null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const CurrencyContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const CurrencySelect = styled.select`
  width: 9.2rem;
  height: 6rem;
  padding: 1.2rem 2rem;
  border: 0.1rem solid var(--gray-300);
  border-radius: 0.4rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  appearance: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const InputContainer = styled.div`
  position: relative;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .currency-input {
    width: 32rem;
    height: 6rem;
    padding: 2rem 5rem 2rem 2rem;
    border: 0.1rem solid var(--gray-300);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
    text-align: right;
  }
  .sign {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface CurrencyProps {
  control: Control<any>;
  currencyType: string;
  onChange: (value: string) => void;
  errorMessage: string;
}

const formatWithCommas = (value: number) => {
  return value.toLocaleString();
};

const Currency = ({
  control,
  currencyType,
  onChange,
  errorMessage,
}: CurrencyProps) => {
  const selectedCurrency = getCurrencyByValue(currencyType);
  const selectedSign = selectedCurrency ? selectedCurrency.sign : "";

  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>Amount</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <CurrencyContainer>
        <Controller
          control={control}
          name="currencyType"
          render={({ field }) => (
            <CurrencySelect
              {...field}
              value={currencyType}
              onChange={(e) => {
                onChange(e.target.value);
                field.onChange(e);
              }}
            >
              {currencyList.map((currency) => (
                <option key={currency.id} value={currency.value}>
                  {currency.label}
                </option>
              ))}
            </CurrencySelect>
          )}
        />
        <InputContainer>
          <Controller
            control={control}
            name="amount"
            render={({ field }) => (
              <input
                className="currency-input"
                type="text"
                value={formatWithCommas(Number(field.value) || 0)}
                onChange={(e) => {
                  const numericValue = Number(e.target.value.replace(/,/g, ""));
                  if (!isNaN(numericValue)) {
                    field.onChange(numericValue);
                  }
                }}
              />
            )}
          />
          <span className="sign">{selectedSign}</span>
        </InputContainer>
      </CurrencyContainer>
    </Container>
  );
};

export default Currency;
