import axios from "axios";
import { SignupFormData } from "../components/auth/SignupForm";

export const postSignup = async (data: SignupFormData) => {
  const response = await axios.post(
    "https://dev-api.celevu.ai/api/v1/users/signup",
    data
  );

  return response.data;
};
