import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
//import { Page, PageWithNavBack } from "./pages/Page";
import CelebPage from "./pages/CelebPage";
import CelebListPage from "./pages/CelebListPage";
import SignupPage from "./pages/SignupPage";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import RequestContractPage from "./pages/RequestContractPage";
import ProjectContent from "./components/dashboard/ProjectContent";
import Contract from "./components/dashboard/Contract";
import Favorite from "./components/dashboard/advertiser/Favorite";
import RouteProtect from "./pages/RouteProtect";
import ProjectDetail from "./components/dashboard/ProjectDetail";
import ContractDetail from "./components/dashboard/advertiser/ContractDetail";
import CreateContract from "./components/contract/CreateContract";
import CreateProject from "./components/advertiser/CreateProject";
import CelebContent from "./components/dashboard/celebrityAgency/CelebContent";
import { useAuth } from "./hooks/useAuth";
import MyCelebList from "./components/dashboard/celebrityAgency/MyCelebList";
import ContractListForCelebAgency from "./components/dashboard/celebrityAgency/ContractListForCelebAgency";
import ProjectListForAdvertiser from "./components/dashboard/advertiser/ProjectListForAdvertiser";
import CelebListContent from "./components/dashboard/advertiser/CelebListContent";
import ContractHistoryPage from "./pages/ContractHistoryPage";

function App() {
  const { user, isPending } = useAuth();

  if (isPending) <>Loading</>;

  const advertiserRoutes = (
    <>
      <Route path="projects" element={<ProjectContent />}>
        <Route index element={<Navigate to="list?type=ongoing" replace />} />
        <Route path="list" element={<ProjectListForAdvertiser />} />
        <Route path="create" element={<CreateProject />} />
        <Route path="detail" element={<ProjectDetail />} />
        <Route path="contract" element={<ContractDetail />}></Route>
      </Route>
      <Route path="celebs" element={<CelebListContent />}>
        <Route path="contract" element={<Contract />}>
          <Route path="create" element={<CreateContract />} />
        </Route>
        <Route path="favorite" element={<Favorite />} />
      </Route>
    </>
  );

  const celebrityAgnecyRoutes = (
    <>
      <Route path="celebs" element={<CelebContent />}>
        <Route index element={<Navigate to="list?type=active" replace />} />
        <Route path="list" element={<ContractListForCelebAgency />} />
        <Route path="contract" element={<ContractDetail />}></Route>
        <Route path="my-celebs" element={<MyCelebList />} />
      </Route>
    </>
  );

  return (
    <div className="App">
      <Routes>
        {/*<Route path="/" element={<Page />}>*/}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route
          path="/dashboard"
          element={
            <RouteProtect>
              <DashboardPage />
            </RouteProtect>
          }
        >
          {user?.companyType === "advertiser" && (
            <>
              <Route index element={<Navigate to="projects/" replace />} />
              {advertiserRoutes}
            </>
          )}
          {user?.companyType === "celebrity_agency" && (
            <>
              <Route index element={<Navigate to="celebs/" replace />} />
              {celebrityAgnecyRoutes}
            </>
          )}
        </Route>

        <Route
          path="/history/:id"
          element={
            <RouteProtect>
              <ContractHistoryPage />
            </RouteProtect>
          }
        />
        <Route path="/celebs/" element={<CelebListPage />} />
        <Route path="/celebs/:id" element={<CelebPage />} />
        <Route path="/celebs/:id/contract" element={<RequestContractPage />} />
        {/*<Route
          path="*"
          element={
            <Navigate
              to={
                userType === "ADVERTISER"
                  ? "/"
                  : "/"
              }
              replace
            />
          }
        />*/}
        {/*</Route>*/}
      </Routes>
    </div>
  );
}

export default App;
