import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

interface LogoProps {
  margin?: string;
  width?: string;
  height?: string;
  url?: string;
}

interface LogoWrapperProps {
  $margin?: string;
  $width?: string;
  $height?: string;
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  margin: ${(props) => props.$margin};
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  cursor: pointer;
  background-image: url("/svg/celevu_logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Logo = ({
  margin = "0 auto",
  width = "22rem",
  height = "4rem",
  url,
}: LogoProps) => {
  return (
    <Link to={url ? url : ""}>
      <LogoWrapper $margin={margin} $width={width} $height={height} />
    </Link>
  );
};

export default Logo;
