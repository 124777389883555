import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import ImageContainer from "./ImageContainer";

const ViewContainer = styled.div<{
  $height: number;
  $maxWidth: number;
  $gap: number;
  $unit: string;
  $scrollPadding?: boolean;
}>`
  width: 100%;
  max-width: ${({ $maxWidth }) => $maxWidth}${({ $unit }) => $unit};
  height: ${({ $scrollPadding, $height, $gap, $unit }) =>
    $scrollPadding ? `${$height + $gap}${$unit}` : `${$height}${$unit}`};
  overflow-x: scroll;
  overflow-y: hidden;
  background-color: red;
`;

interface ImageListProps {
  $gap: number;
  $length: number;
  $imgWidth: number;
  $unit: string;
}

const ImageList = styled.div<ImageListProps>`
  display: flex;
  gap: ${(props) => props.$gap}${({ $unit }) => $unit};
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  width: ${({ $imgWidth, $length, $gap }) =>
      $imgWidth * $length + ($length - 1) * $gap}${({ $unit }) => $unit};
  /*padding-bottom: 2rem;*/
  /*background-color: red;*/
`;

interface ImageScrollProps {
  imgList: string[];
  maxWidth: number;
  width?: number;
  height?: number;
  gap?: number;
  unit?: string;
  borderRadius?: number;
  objectFit?: string;
}

const ImageScroll = ({
  imgList,
  maxWidth,
  width = 20,
  height = 20,
  unit = "rem",
  gap = 1.2,
  borderRadius = 0,
  objectFit,
}: ImageScrollProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      container.scrollLeft += e.deltaY;
    };

    container.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const imageLength = width * imgList.length + (imgList.length - 1) * gap;

  return (
    <ViewContainer
      ref={containerRef}
      $height={height}
      $maxWidth={maxWidth}
      $gap={gap}
      $scrollPadding={imageLength > maxWidth}
      $unit={unit}
    >
      <ImageList
        $gap={gap}
        $length={imgList.length}
        $imgWidth={width}
        $unit={unit}
      >
        {imgList.map((img, index) => (
          <ImageContainer
            key={index}
            width={width}
            height={height}
            unit={unit}
            borderRadius={borderRadius}
            objectFit={objectFit}
          >
            <img src={img} alt={`selected stock ${index + 1}`} />
          </ImageContainer>
        ))}
      </ImageList>
    </ViewContainer>
  );
};

export default ImageScroll;
