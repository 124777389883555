import React from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { ContractContext } from "./CelebContent";
import { findMatchingStatusLabel } from "../../../utils/utils";
import { statusList } from "../../../configs/contractStatus";

const TableContainer = styled.ul`
  margin-top: 2.8rem;
  border-top: 0.5rem solid var(--gray-900);
  > :first-child {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    border-bottom: 0.1rem solid var(--gray-900);
    cursor: default;
  }
`;

const TableRow = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: 10rem 14rem 30rem 14rem 30rem 10rem;
  /*grid-template-columns: 1fr 1.4fr 3fr 1.4fr 3fr 1fr;*/
  padding: 2rem 2rem;
  height: 6rem;
  text-align: center;
  border-bottom: 0.1rem solid var(--gray-300);
  cursor: pointer;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    &.status {
      width: 100%;
      border-radius: 1.2rem;
      padding: 0.2rem 1rem;
      color: var(--gray-050);
      background-color: var(--purple-300);
    }
    &.bold {
      ${({ theme }) => theme.font.bold};
    }
    &.italic {
      ${({ theme }) => theme.font.italic};
    }
  }
`;

const ContractListForCelebAgency = () => {
  const [searchParams] = useSearchParams();
  const { activeContracts, inactiveContracts } =
    useOutletContext<ContractContext>();
  const navigate = useNavigate();
  const contractList =
    searchParams.get("type") === "active" ? activeContracts : inactiveContracts;

  return (
    <>
      <TableContainer>
        <TableRow>
          <span>Status</span>
          <span>Artist</span>
          <span>Project title</span>
          <span>Advertiser</span>
          <span>Type</span>
          <span>Amount</span>
        </TableRow>
        {contractList?.map((contract) => {
          return (
            <TableRow
              key={contract.id}
              onClick={() =>
                navigate(`../contract?contractId=${contract.contractId}`)
              }
            >
              <span className="status">
                {findMatchingStatusLabel(statusList, contract.status)}
              </span>
              <span>{contract.celebName}</span>
              <span className="bold">{contract.projectTitle}</span>
              <span className="bold">{contract.advertiser.companyName}</span>
              <span>{contract.contractType}</span>
              <span>{contract.amount}</span>
            </TableRow>
          );
        })}
      </TableContainer>
    </>
  );
};

export default ContractListForCelebAgency;
