import React from "react";
import styled from "styled-components";
import { FilterQuery } from "../celeb/CelebList";

const CountrySelect = styled.select`
  width: 25.6rem;
  height: 4rem;
  padding: 1rem 2rem;
  border: 0.1rem solid var(--purple-300);
  border-radius: 2rem;
  color: var(--gray-900);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

const countryList = [
  { value: "", displayName: "Worldwide" },
  { value: "SOUTH_KOREA", displayName: "South Korea" },
  { value: "USA", displayName: "United States" },
  { value: "FRANCE", displayName: "France" },
  { value: "UK", displayName: "United Kingdom" },
  { value: "GERMANY", displayName: "Germany" },
];

interface CountryProps {
  query: FilterQuery;
  setQuery: Function;
}

const Country = ({ query, setQuery }: CountryProps) => {
  return (
    <CountrySelect
      id="country-select"
      name="country"
      value={query.country}
      onChange={(e) => setQuery("country", e.target.value)}
    >
      {countryList.map((country, index) => {
        return (
          <option key={"select_option_" + country.value} value={country.value}>
            {country.displayName}
          </option>
        );
      })}
    </CountrySelect>
  );
};

export default Country;
