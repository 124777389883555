import React from "react";
import styled from "styled-components";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AgreeCheck, Continue } from "./Signup";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4px;
`;

const CheckButton = styled.div<{ $status: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ $status }) =>
    $status ? "var(--purple-300)" : "var(--gray-500)"};
  .MuiSvgIcon-root {
    font-size: 2.4rem;
  }
`;

const CheckText = styled.li`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0.8rem 0;
  gap: 0.8rem;
  align-items: center;
  > .MuiSvgIcon-root {
    margin-left: auto;
    font-size: 2.4rem;
    color: var(--gray-500);
  }
`;

const Description = styled.span`
  color: var(--gray-600);
  white-space: pre-wrap;
  ${({ theme }) => theme.font.body3};
  ${({ theme }) => theme.font.regular};
  margin-left: 3.4rem;
`;

const CheckList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Border = styled.div`
  border-top: 0.1rem solid var(--gray-300);
  width: 95%;
  margin: 2rem auto;
`;

interface CheckProps {
  value: boolean;
  setValue: () => void;
}

const Check = ({ value, setValue }: CheckProps) => {
  const icon = value ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />;
  return (
    <CheckButton onClick={setValue} $status={value}>
      {icon}
    </CheckButton>
  );
};

interface AgreementProps {
  agreeStatus: AgreeCheck;
  setAgreeStatus: (agreeStatus: AgreeCheck) => void;
  buttonFn: () => void;
}

const documentList = [
  {
    key: "agree",
    title: "Terms of Service 01",
    mandatory: true,
  },
  {
    key: "marketAgree",
    title: "Terms of Service 02",
    mandatory: false,
  },
];

const Agreement = ({
  agreeStatus,
  setAgreeStatus,
  buttonFn,
}: AgreementProps) => {
  const isAllChecked = agreeStatus.agree && agreeStatus.marketAgree;

  const handleCheck = (key: keyof AgreeCheck) => {
    setAgreeStatus({ ...agreeStatus, [key]: !agreeStatus[key] });
  };

  const handleCheckAll = () => {
    const newStatus = !isAllChecked;
    setAgreeStatus({
      agree: newStatus,
      marketAgree: newStatus,
    });
  };

  return (
    <Container>
      <CheckText>
        <Check value={isAllChecked} setValue={handleCheckAll} />
        Agree to all.
      </CheckText>
      <Description>
        Please note that agreeing to all includes both mandatory and{"\n"}
        optional information.{"\n"}You may choose to agree to each item
        individually.{"\n"}Even if you refuse to consent to optional items,
        {"\n"}you can still use the service.
      </Description>
      <Border />
      <CheckList>
        {documentList.map((document) => (
          <CheckText key={document.key}>
            <Check
              value={agreeStatus[document.key as keyof AgreeCheck]}
              setValue={() => handleCheck(document.key as keyof AgreeCheck)}
            />
            {document.mandatory ? "[Mandatory] " : "[Optional]  "}
            {document.title}
            <KeyboardArrowRightIcon />
          </CheckText>
        ))}
      </CheckList>
      <Continue onClick={buttonFn} disabled={!agreeStatus.agree}>
        Continue
      </Continue>
    </Container>
  );
};

export default Agreement;
