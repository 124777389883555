import { useState } from "react";
import { AdvertisingProductImage } from "../interfaces/contract.interface";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import formatDate from "../../utils/utils";

const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 12rem;
  > .content {
    width: 12rem;
    cursor: pointer;
    position: relative;
    > .title {
      color: var(--gray-900);
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
      text-align: left;
      padding: 0.4rem 0;
    }
  }
  > .sub-content {
    margin-left: auto;
    width: 8rem;
    height: 8rem;
    background-color: gray;
  }
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.font.body4};
  ${({ theme }) => theme.font.regular};
  height: 2rem;
  background-color: var(--white);
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  &.reject {
    color: var(--crimson-600);
    &::before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-image: url("/svg/rejected.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.confirm {
    color: var(--blue-500);
    &::before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-image: url("/svg/confirmed.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

interface SlotProps {
  productImage: AdvertisingProductImage;
  onClickFn: (data: AdvertisingProductImage) => void;
}

const ContentSlot = ({ productImage, onClickFn }: SlotProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickSlot = () => {
    setIsOpen(!isOpen);
    onClickFn(productImage);
  };

  const renderLabel = () => {
    switch (productImage.isConfirm) {
      case true:
        return <StatusLabel className="confirm">Confirmed</StatusLabel>;
      case false:
        return <StatusLabel className="reject">Rejected</StatusLabel>;
      default:
        <></>;
    }
  };

  return (
    <SlotContainer>
      <div className="content" onClick={() => onClickSlot()}>
        <ImageContainer width={12} height={12} position="static">
          <img src={productImage.imgUrl} alt="test" />
        </ImageContainer>
        {renderLabel()}
        <div className="title">{formatDate(productImage.createdAt)}</div>
      </div>
      {isOpen && (
        <>
          {productImage.children.map((children) => {
            return <div className="sub-content"></div>;
          })}
        </>
      )}
    </SlotContainer>
  );
};

export default ContentSlot;
