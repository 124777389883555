import React from "react";
import styled from "styled-components";
import { CelebSummaryData } from "../../interfaces/celeb.interfaces";
import CelebCard from "../../celeb/CelebCard";
import { useQuery } from "@tanstack/react-query";
import { getFavoriteCelebList } from "../../../api/celebList";
import { BaseResponse } from "../../interfaces/auth.interfaces";

const CelebCardListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 30rem;
  gap: 1.6rem;
`;

type FavoriteCelebList = BaseResponse<CelebSummaryData[]>;

const Favorite = () => {
  const { isPending, isError, data, error, refetch } =
    useQuery<FavoriteCelebList>({
      queryKey: ["FavoriteCelebList"],
      queryFn: () => getFavoriteCelebList(),
    });

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <CelebCardListContainer>
      {data.data.map((celeb, index) => {
        return <CelebCard key={celeb.id + index} celebData={celeb} />;
      })}
    </CelebCardListContainer>
  );
};

export default Favorite;
