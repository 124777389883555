import React, { useState } from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { putLike } from "../../api/like";
import { AxiosError } from "axios";

const FavoriteWrapper = styled.div`
  cursor: pointer;
  font-size: 3rem;
  path {
    stroke-width: 0.1;
  }
  .unlike {
    color: var(--crimson-600);
  }
  .like {
    color: var(--white);
  }
`;

interface FavoriteProps {
  id: string;
  likeStatus: boolean;
}

interface LikeResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: boolean;
}

interface LikeErrorResponse {
  ok: boolean;
  statusCode: number;
  data: string;
}

const Favorite = ({ id, likeStatus }: FavoriteProps) => {
  const [isLike, setIsLike] = useState<boolean>(likeStatus);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    mutation.mutate(id);
  };

  const mutation = useMutation<
    LikeResponse,
    AxiosError<LikeErrorResponse>,
    string
  >({
    mutationFn: (id: string) => putLike(id),
    onSuccess: (data) => {
      setIsLike(data.data);
    },
    onError: (error) => {},
  });

  if (isLike)
    return (
      <FavoriteWrapper>
        <FavoriteIcon
          className="unlike"
          fontSize="inherit"
          sx={{ strokeWidth: 0.5 }}
          onClick={(event) => handleClick(event)}
        />
      </FavoriteWrapper>
    );
  return (
    <FavoriteWrapper>
      <FavoriteBorderIcon
        className="like"
        fontSize="inherit"
        sx={{ strokeWidth: 0.5 }}
        onClick={(event) => handleClick(event)}
      />
    </FavoriteWrapper>
  );
};

export default Favorite;
