import React from "react";
import { LayoutWithOutNavbar } from "../components/layout/Layout";
import Signup from "../components/auth/Signup";

const SignupPage = () => {
  return (
    <LayoutWithOutNavbar>
      <Signup />
    </LayoutWithOutNavbar>
  );
};

export default SignupPage;
