import React from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useQuery } from "@tanstack/react-query";
import { getProjectList } from "../../api/project";
import {
  ProjectData,
  ProjectStatusList,
} from "../interfaces/project.interface";
import Check from "../button/Check";
import StyledButton from "../button/StyledButton";

const ProjectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Project = styled.div`
  width: 42rem;
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 2.6rem 4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  background: var(--white);
  justify-content: center;
  cursor: pointer;
  .title {
    margin-left: 2rem;
    width: 16rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date {
    width: 10rem;
    overflow: hidden;
    margin-left: auto;
    white-space: nowrap;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  > .add-project-wrapper {
    font-size: 4rem;
  }
  /*
  &:hover,
  &.selected {
    border: 0.2rem solid var(--purple-050);
    box-shadow: 0px 0px 10px 0px #f3e7fe;
  }*/
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
  margin-top: 3rem;
`;

interface SelectedProjectProps {
  selectedProject: ProjectData | null;
  selectedProjectHandler: (project: ProjectData) => void;
  nextStepHandler: () => void;
  prevStepHandler: () => void;
}

const SelectProject = ({
  selectedProject,
  selectedProjectHandler,
  nextStepHandler,
  prevStepHandler,
}: SelectedProjectProps) => {
  const { data, isPending } = useQuery<ProjectStatusList>({
    queryKey: ["ProjectList"],
    queryFn: () => getProjectList(),
  });

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <ProjectListContainer>
      {data.data.ongoingProjects.map((project) => {
        const date = new Date(project.createdAt);
        const formatDate = date
          .toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "2-digit",
          })
          .replace(",", ".");

        return (
          <Project
            key={project.id}
            onClick={() => selectedProjectHandler(project)}
          >
            <Check checked={selectedProject === project} size="lg" />
            <span className="title">{project.title}</span>
            <span className="date">{formatDate}</span>
          </Project>
        );
      })}

      <Project>
        <div className="add-project-wrapper">
          <AddIcon
            fontSize="inherit"
            sx={{
              stroke: "var(--white)",
              strokeWidth: "0.1rem",
            }}
          />
        </div>
      </Project>

      <ButtonContainer>
        <StyledButton
          label="Previous"
          size="sm"
          color="white"
          onClick={() => prevStepHandler()}
        />
        <StyledButton
          label="Next"
          size="sm"
          color="black"
          disabled={!selectedProject}
          onClick={() => nextStepHandler()}
        />
      </ButtonContainer>
    </ProjectListContainer>
  );
};

export default SelectProject;
